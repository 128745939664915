import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { darker, formatDate, lighter } from '../../helpers/functions';

function Countdown({ targetDate, MainColor, theme, font, dev, colorPalette, color }) {

    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const { days, hours, minutes, seconds } = timeLeft;

    return (
        <div className="date-container">
            <p className={!dev ? "date-date" : "date-date-dev"} style={{ color: !color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : color, fontFamily: font }}>
                {formatDate(targetDate)}
            </p>
            <hr
                className={!dev ? "date-divider" : "date-divider-dev"}
                style={{
                    border: `1px solid ${!color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : color}`,
                    color: !color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : color
                }}
            />
            <Row className={!dev ? "date-row" : "date-row-dev"}>
                {['days', 'hours', 'minutes', 'seconds'].map(unit => (
                    <Col key={unit} className="date-col">
                        <p className="date-time" style={{ color: !color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : color, fontFamily: font }}>
                            {eval(unit)}
                        </p>
                        <p className="date-unit" style={{ color: !color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : color, fontFamily: font }}>
                            {eval(unit) === 1 ? unit.slice(0, -1) : unit}
                        </p>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export function CountdownDev({ targetDate, MainColor, theme, font, dev, colorPalette, color }) {

    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const { days, hours, minutes, seconds } = timeLeft;

    return (
        <div className="date-container">
            <p className={!dev ? "date-date" : "date-date-dev"} style={{ color: !color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : color, fontFamily: font, fontSize: '14px', fontWeight: '600' }}>
                {formatDate(targetDate)}
            </p>
            <hr
                className={!dev ? "date-divider" : "date-divider-dev"}
                style={{
                    border: `1px solid ${!color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : color}`,
                    color: !color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : color,
                    margin: '8px 0',
                    height: '1px'
                }}
            />
            <Row className={!dev ? "date-row" : "date-row-dev"}>
                {['days', 'hours', 'minutes', 'seconds'].map(unit => (
                    <Col key={unit} className="date-col">
                        <p className="date-time" style={{ color: !color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : color, fontFamily: font, fontSize: '18px', margin: 0 }}>
                            {eval(unit)}
                        </p>
                        <p className="date-unit" style={{ color: !color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : color, fontFamily: font, fontSize: '12px', margin: 0 }}>
                            {eval(unit) === 1 ? unit.slice(0, -1) : unit}
                        </p>
                    </Col>
                ))}
            </Row>
        </div>
    );
}


export default Countdown;
