import { Button, Col, ColorPicker, DatePicker, Input, Popconfirm, Row, Select, Slider, Switch, Upload, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

import { formatDateToISO, fonts, generateImagesName, IoClose, IoMdAdd, colorFactoryToHex, buttonsColorText, lighter, formatDate } from '../../helpers'
import { appContext } from '../../context'

import {
    HiSwitchVertical, LuImagePlus, LuSettings2, FaMinus, LuFrame, FaRegCalendar, BiHide, BiShow,
    FaArrowRightLong, FaArrowLeftLong
} from '../../helpers';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;



export const BuildCover = ({ invitation, setInvitation, setSaved, invitationID, onDesign, onTry }) => {

    const { setFont } = useContext(appContext)

    const coordenadas = [
        {
            justify: 'left',
            align: 'flex-start',
            active: null,
            text: 'Superior izquierda'
        },
        {
            justify: 'center',
            align: 'flex-start',
            active: null,
            text: 'Superior centro'
        },
        {
            justify: 'right',
            align: 'flex-start',
            active: null,
            text: 'Superior derecha'
        },
        {
            justify: 'left',
            align: 'center',
            active: null,
            text: 'Centro izquierda'
        },
        {
            justify: 'center',
            align: 'center',
            active: null,
            text: 'Centro'
        },
        {
            justify: 'right',
            align: 'center',
            active: null,
            text: 'Centro derecha'
        },
        {
            justify: 'left',
            align: 'flex-end',
            active: null,
            text: 'Inferior izquierda'
        },
        {
            justify: 'center',
            align: 'flex-end',
            active: null,
            text: 'Inferior centro'
        },
        {
            justify: 'right',
            align: 'flex-end',
            active: null,
            text: 'Inferior derecha'
        },
    ]

    const [position, setPosition] = useState(coordenadas)
    const [datePosition, setDatePosition] = useState(true)
    const [settingsMode, setSettingsMode] = useState(false)

    const [imageMode, setImageMode] = useState(false)
    const [itemAlign, setitemAlign] = useState('')
    const [itemJustify, setitemJustify] = useState('')
    const [url, setUrl] = useState(null);
    const [firstColor, setFirstColor] = useState(null)
    const [secondColor, setSecondColor] = useState(null)
    const [location, setLocation] = useState('cover')
    const [posicion, setPosicion] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [currentTimer, setCurrentTimer] = useState(0)
    const [timerColor, setTimerColor] = useState(null)

    useEffect(() => {
        if (invitation) {

            const colors = extractAndCompareColors(invitation.cover.background)
            setitemAlign(invitation.cover.align)
            setitemJustify(invitation.cover.justify)
            setTimerColor(invitation.cover.timerColor)
            if (colors) {
                setFirstColor(colors.color1)
                setSecondColor(colors.color2)
            }


            if (invitation.cover.flexDirection === 'column') {
                setDatePosition(true)
            } else setDatePosition(false)
        }
    }, [invitation])


    const onChangeDate = (e) => {
        setSaved(false)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                date: e ? formatDateToISO(e) : null,
            },
        }));
    }

    const handleURL = (url) => {
        // setUrl(url)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                featured_dev: url,
                background: null
            },
        }));
        setSaved(false)
    }


    const handlePosition = (justify, align) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                justify: justify,
                align: align,
            },
        }));
        setSaved(false)
    }

    const handleFlexDirection = (value) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                flexDirection: value ? 'column' : 'column-reverse',
            },
        }));
        setSaved(false)
    }

    const onChangeTitle = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                title: e ? e.target.value : prevInvitation.cover.title,
            },
        }));
        setSaved(false)
    }

    const handleFont = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                image: e,
            },
        }));
        setSaved(false)
        // setFont(e)
    }

    const switchSettings = (type, value) => {
        if (type === 'image') {
            if (value) {
                setImageMode(true);
                setSettingsMode(false);
                setLocation('image')
            } else if (!value && !settingsMode) {
                setImageMode(false);
            }
        } else if (type === 'settings') {
            if (value) {
                setSettingsMode(true);
                setImageMode(false);
                setLocation('settings')
            } else if (!value && !imageMode) {
                setSettingsMode(false);
            }
        }
    };


    const handleCustomRequest = ({ file }) => {
        if (!file) return;

        const isLt3M = file.size / 1024 / 1024 < 5;
        if (!isLt3M) {
            message.error('El archivo debe ser menor a 5MB.');
            return; // Salir de la función si el archivo es mayor a 3MB
        }

        const storageRef = ref(storage, `invitations/${invitationID}/cover/${generateImagesName()}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                // setProgress(progress);
            },
            error => {
                console.error('Upload error: ', error);
            },

            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                    handleURL(downloadURL)
                });
            }
        );
    };


    const removeImageDB = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                featured_dev: null,
            },
        }));
        setSaved(false)
    };

    const onChnageTitleColor = (e) => {
        setFirstColor(colorFactoryToHex(e))
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                color: colorFactoryToHex(e),
            },
        }));
        setSaved(false)
    };

    const onChnageTimerColor = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                timerColor: colorFactoryToHex(e),
            },
        }));
        setSaved(false)
    };

    const onChnageFirstColor = (e) => {
        setFirstColor(colorFactoryToHex(e))
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                background: `linear-gradient(to bottom, ${colorFactoryToHex(e)} 0%, ${secondColor ? secondColor : colorFactoryToHex(e)} 100%)`,
            },
        }));
        setSaved(false)
    };

    const onChnageSecondColor = (e) => {
        setSecondColor(colorFactoryToHex(e))
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                background: `linear-gradient(to bottom, ${firstColor} 0%, ${colorFactoryToHex(e)} 100%)`,
            },
        }));
        setSaved(false)
    };

    const addSecondColor = (e) => {
        setSecondColor(e)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                background: `linear-gradient(to bottom, ${firstColor} 0%, ${e} 100%)`,
            },
        }));
        setSaved(false)
    };

    const extractAndCompareColors = (gradientString) => {

        if (!gradientString) {
            return {
                color1: null,
                color2: null,
                areEqual: true
            };
        }
        // Expresión regular para encontrar colores hexadecimales en el string
        const hexColorRegex = /#([A-Fa-f0-9]{6})/g;
        // Extraer los colores
        const colors = gradientString.match(hexColorRegex);

        if (colors && colors.length >= 2) {
            // Obtener los dos primeros colores
            const color1 = colors[0];
            const color2 = colors[1];

            // Comparar los colores
            const areEqual = color1.toLowerCase() === color2.toLowerCase();

            return {
                color1: color1,
                color2: color2,
                areEqual: areEqual
            };
        } else {
            return {
                color1: null,
                color2: null,
                areEqual: true
            };
        }
    }

    const handletitle = (location) => {
        switch (location) {
            case 'cover': return 'Generales'
            case 'image': return 'Fondo'
            case 'settings': return 'Ajustes'
            case 'date': return 'Fecha'

            default:
                break;
        }
    }

    const moveImage = (direction) => {
        const step = 10; // Define el paso de movimiento
        switch (direction) {
            case 'left':
                setPosicion({ ...posicion, x: posicion.x - step });
                break;
            case 'right':
                setPosicion({ ...posicion, x: posicion.x + step });
                break;
            case 'up':
                setPosicion({ ...posicion, y: posicion.y - step });
                break;
            case 'down':
                setPosicion({ ...posicion, y: posicion.y + step });
                break;
            default:
                break;
        }
    };


    const handleZoom = (value) => {
        setZoom(value);
    };

    const handleColorAuto = (e) => {

        if (e) {
            if (invitation.cover.background) {
                const colors = extractAndCompareColors(invitation.cover.background)
                setInvitation(prevInvitation => ({
                    ...prevInvitation,
                    cover: {
                        ...prevInvitation.cover,
                        color: buttonsColorText(colors.color1),
                    },
                }));
            } else {
                // theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6)
                setInvitation(prevInvitation => ({
                    ...prevInvitation,
                    cover: {
                        ...prevInvitation.cover,
                        color: invitation.generals.theme ? lighter(invitation.generals.palette.primary, 0.6) : lighter(invitation.generals.palette.accent, 0.6),
                    },
                }));
            }
        }

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            cover: {
                ...prevInvitation.cover,
                auto: e,
            },
        }));


        setSaved(false)


    }

    const timerTypes = [
        {
            borderRadius: '12px',
            background: 'red',
            width: '90%',
            padding: '10px 0'
        },
        {
            borderRadius: '0px',
            background: 'red',
            width: '100%',
            padding: '10px 0'
        },
        {
            borderRadius: '0px',
            background: 'transparent',
            width: '100%',
            padding: '10px 0'
        }
    ]

    const types = [
        {
            name: 'Redondeado',
            content: {
                borderRadius: '12px',
                background: timerColor,
                width: '90%',
                padding: '10px 0'
            },
        },
        {
            name: 'Completo',
            content: {
                borderRadius: '0px',
                background: timerColor,
                width: '100%',
                padding: '10px 0'
            },
        },
        {
            name: 'Transparente',
            content: {
                borderRadius: '0px',
                background: 'transparent',
                width: '100%',
                padding: '10px 0'
            }
        }
    ]

    const handlePlus = () => {
        if (currentTimer === types.length - 1) {
            setCurrentTimer(0)
        } else {
            setCurrentTimer(currentTimer + 1)
        }
    }

    const handleLess = () => {
        if (currentTimer === 0) {
            setCurrentTimer(types.length - 1)
        } else {
            setCurrentTimer(currentTimer - 1)
        }
    }

    useEffect(() => {
        if (invitation) {
            console.log(formatDate(invitation.cover.date))
        }

        // console.log(dayjs(invitation.cover.date))
    }, [])



    return (
        <>
            {
                invitation ?
                    <div className='build-cover-main-container'>

                        <h2 className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            style={{
                                width: '100%',
                            }}
                        >Portada</h2>

                        {
                            onDesign ?
                                <p className='module--description' >
                                    La portada es donde capturas la atención de tus invitados. Puedes añadir una fotografía de portada que represente el evento, un título llamativo y la fecha clave. Diseña una portada que invite a tus conocidos a querer saber más y marcar la fecha en sus calendarios.</p>
                                : <div className='module--description' style={{ marginBottom: '30px' }} />
                        }

                        <Row className='generals-module-container'>

                            <div className='generals-card-container'>

                                <Row className='gc-title-cta-buttons-container'>
                                    <p className='gc--title'>{handletitle(location)}</p>

                                    <Row className='gc-cta-buttons-container'>



                                        <Button
                                            type='ghost'
                                            onClick={() => setLocation('cover')}
                                            id={`gc-cta-buttons${location === 'cover' ? '--selected' : ''}`}
                                            icon={<LuFrame size={18} />} />


                                        <Button
                                            type='ghost'
                                            // onClick={() => handleFlexDirection(!datePosition)}
                                            onClick={() => setLocation('date')}
                                            id={`gc-cta-buttons${location === 'date' ? '--selected' : ''}`}
                                            icon={<FaRegCalendar size={18} />} />

                                        <Button
                                            type='ghost'
                                            // onClick={() => switchSettings('image', !imageMode)}
                                            onClick={() => setLocation('image')}
                                            id={`gc-cta-buttons${location === 'image' ? '--selected' : ''}`}
                                            style={{ margin: '0px 5px' }}
                                            icon={<LuImagePlus size={18} />} />

                                        <Button
                                            type='ghost'
                                            // onClick={() => switchSettings('settings', !settingsMode)}
                                            onClick={() => setLocation('settings')}
                                            id={`gc-cta-buttons${location === 'settings' ? '--selected' : ''}`}
                                            icon={<LuSettings2 size={18} />} />


                                    </Row>
                                </Row>


                                {
                                    location === 'cover' && (
                                        <Col
                                            className={`gc-content-container${datePosition ? '' : '--reverse'}`}>
                                            <Col className='gc-content-container-module'>

                                                <p className='gc-content-label'>Título</p>

                                                <Input
                                                    placeholder={'Título'}
                                                    value={invitation.cover.title}
                                                    onChange={onChangeTitle}
                                                    className='gc-input-text' />

                                                <p className='gc-content-label'>Posición</p>

                                                <div className='gc-position-container'>

                                                    {
                                                        position.map((item, index) => (
                                                            <div
                                                                key={index}
                                                                onClick={() => handlePosition(item.justify, item.align)}
                                                                className='gc-position-item'>


                                                                {
                                                                    item.justify === itemJustify && item.align === itemAlign ?
                                                                        <div
                                                                            className='gc-position-selected-container '
                                                                            style={{
                                                                                alignItems: itemJustify === 'left' ? 'flex-start' : itemJustify === 'right' ? 'flex-end' : 'center',
                                                                            }}>

                                                                            <div
                                                                                className='gc-position-selected-item'
                                                                                style={{
                                                                                    width: '70%'
                                                                                }} />
                                                                            <div
                                                                                className='gc-position-selected-item'
                                                                                style={{
                                                                                    width: '100%',
                                                                                    margin: '3px 0',
                                                                                }} />
                                                                            <div
                                                                                className='gc-position-selected-item'
                                                                                style={{
                                                                                    width: '30%',
                                                                                }} />



                                                                        </div>

                                                                        : <div style={{
                                                                            height: '5px', aspectRatio: '1', borderRadius: '50%',
                                                                            backgroundColor: '#d9d9d9'
                                                                        }} />


                                                                }

                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            </Col>


                                        </Col>

                                    )
                                }

                                {
                                    location === 'settings' && (
                                        <Col className='gc-content-container'>


                                            <p className='gc-content-label'>Tipo de letra</p>

                                            <Select

                                                value={invitation.cover.image}
                                                onChange={(e) => handleFont(e)}
                                                style={{ width: '100%', fontFamily: invitation.cover.image, }}>
                                                {fonts.map((font, index) => (
                                                    <Option style={{ fontFamily: font }} key={index} value={font}>{font}</Option>
                                                ))}

                                            </Select>

                                            <Col style={{
                                                width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column',
                                                marginTop: '10px'
                                            }}>
                                                <p className='gc-content-label'>Tamaño</p>

                                                <Slider
                                                    style={{ width: '95%', }}
                                                    min={2}
                                                    max={10}
                                                    step={0.01}
                                                    onChange={(e) => setInvitation(prevInvitation => ({
                                                        ...prevInvitation,
                                                        cover: {
                                                            ...prevInvitation.cover,
                                                            fontSize: e ? e : prevInvitation.cover.fontSize,
                                                        },
                                                    }))}
                                                    // onChange={onChange}
                                                    value={invitation.cover.fontSize}
                                                />

                                                <Row style={{
                                                    width: '100%', display: 'flex', alignItems: 'center',
                                                    justifyContent: 'space-between', flexDirection: 'row'
                                                }}>
                                                    <Col style={{
                                                        width: '48%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'
                                                    }}>

                                                        <p className='gc-content-label'>Opacidad</p>


                                                        <Slider
                                                            style={{ width: '95%' }}
                                                            // trackStyle={"#000"}
                                                            // handleStyle={{ borderColor: 'red' }}
                                                            min={0.1}
                                                            max={1}
                                                            step={0.01}
                                                            onChange={(e) => setInvitation(prevInvitation => ({
                                                                ...prevInvitation,
                                                                cover: {
                                                                    ...prevInvitation.cover,
                                                                    opacity: e ? e : prevInvitation.cover.opacity,
                                                                },
                                                            }))}
                                                            // onChange={onChange}
                                                            value={invitation.cover.opacity}
                                                        />

                                                    </Col>

                                                    <Col style={{
                                                        width: '48%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'
                                                    }}>
                                                        <p className='gc-content-label'>Grosor</p>



                                                        <Slider
                                                            style={{ width: '95%' }}
                                                            // trackStyle={"#000"}
                                                            // handleStyle={{ borderColor: 'red' }}
                                                            min={100}
                                                            max={1000}
                                                            step={100}

                                                            // onChange={(e) => console.log(e)}

                                                            onChange={(e) => setInvitation(prevInvitation => ({
                                                                ...prevInvitation,
                                                                cover: {
                                                                    ...prevInvitation.cover,
                                                                    fontWeight: e ? e : prevInvitation.cover.fontWeight,
                                                                },
                                                            }))}

                                                            value={invitation.cover.fontWeight}
                                                        />

                                                    </Col>
                                                </Row>

                                                {/* </Col> */}



                                            </Col>

                                            <Row style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                                flexDirection: 'row', width: '100%'
                                            }}>
                                                <p className='gc-content-label'>Color</p>
                                                <Switch
                                                    size='small'
                                                    checkedChildren="Automático" unCheckedChildren="Manual"
                                                    checked={invitation.cover.auto}
                                                    onChange={(e) => handleColorAuto(e)}
                                                />
                                            </Row>




                                            <ColorPicker
                                                disabled={invitation.cover.auto ? true : false}
                                                value={invitation.cover.color}
                                                onChange={(e) => onChnageTitleColor(e)}
                                            >
                                                <Button

                                                    style={{
                                                        backgroundColor: invitation.cover.color,
                                                        color: buttonsColorText(invitation.cover.color ? invitation.cover.color.toString() : '#000000')

                                                    }}
                                                    id='cover-pick-title-color'>
                                                    {invitation.cover.color}
                                                </Button>
                                            </ColorPicker>

                                        </Col>
                                    )
                                }

                                {
                                    location === 'image' && (
                                        <Col className='gc-content-container'>
                                            {
                                                invitation.cover.featured_dev ?

                                                    // <div className='featured-controls-container'>
                                                    <div className='gc-edit-featured-container' style={{ position: 'relative' }}>

                                                        <img src={invitation.cover.featured_dev}
                                                            style={{ transform: `translate(${position.x}px, ${position.y}px) scale(${zoom})` }}
                                                        />

                                                        <Button
                                                            type='ghost'
                                                            id="gc-cta-buttons-static"
                                                            onClick={removeImageDB}
                                                            icon={<IoClose size={20} />}
                                                            style={{ position: 'absolute', top: '5px', right: '5px', }}
                                                        />





                                                        {/* </div> */}

                                                    </div> :


                                                    <div style={{
                                                        width: '100%',
                                                        display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'
                                                    }}>

                                                        <div className='cover-background-container'>
                                                            <span>Imagen de fondo</span>
                                                            <div style={{
                                                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                marginTop: '35px', marginBottom: '30px',
                                                            }}>


                                                                {
                                                                    onTry ? <Button
                                                                        onClick={() => message.warning('No puedes subir imágenes en simulador')}
                                                                        id="add-new-color-button" icon={<IoMdAdd />}></Button>

                                                                        : <Upload

                                                                            onChange={handleCustomRequest}
                                                                            showUploadList={false} // Oculta la lista de archivos subidos
                                                                            beforeUpload={() => false} // Evita la carga automática de archivos

                                                                        // style={{ width: '200px' }}
                                                                        >

                                                                            <Button id="add-new-photo-button" icon={<IoMdAdd />}></Button>

                                                                        </Upload>
                                                                }




                                                            </div>




                                                        </div>


                                                        <div className='cover-background-container'>
                                                            <span>Color de fondo</span>
                                                            <div className='cover-color-picker-container'
                                                                style={{
                                                                    justifyContent: secondColor != firstColor ? 'space-evenly' : 'flex-start'
                                                                }}
                                                            >
                                                                <ColorPicker
                                                                    value={firstColor}
                                                                    onChange={(e) => onChnageFirstColor(e)}
                                                                // className='gc-color-picker'
                                                                // style={{ backgroundColor: invitation.generals.palette.base }}
                                                                >
                                                                    <Button
                                                                        // icon={<TbClick size={18} />}
                                                                        style={{
                                                                            backgroundColor: firstColor,
                                                                            width: secondColor ? '30%' : '100px'
                                                                            // position: 'absolute', right: '8px',
                                                                            // opacity: onPalette ? 1 : 0,
                                                                            // border: `1px solid ${buttonsColorText(invitation.generals.palette.buttons.toString())}`
                                                                        }}
                                                                        id='cover-pick-color-button' />
                                                                </ColorPicker>

                                                                {
                                                                    secondColor != firstColor ?
                                                                        <ColorPicker
                                                                            value={secondColor}
                                                                            onChange={(e) => onChnageSecondColor(e)}
                                                                        // className='gc-color-picker'
                                                                        // style={{ backgroundColor: invitation.generals.palette.base }}
                                                                        >
                                                                            <Button
                                                                                // icon={<TbClick size={18} />}
                                                                                style={{
                                                                                    backgroundColor: secondColor,
                                                                                    width: '30%',
                                                                                    position: 'relative'
                                                                                    // position: 'absolute', right: '8px',
                                                                                    // opacity: onPalette ? 1 : 0,
                                                                                    // border: `1px solid ${buttonsColorText(invitation.generals.palette.buttons.toString())}`
                                                                                }}
                                                                                id='cover-pick-color-button'>
                                                                                {/* <Button
                                                                                type='ghost'
                                                                                id="gc-cta-buttons-static"
                                                                                onClick={() => setSecondColor(firstColor)}
                                                                                icon={<IoClose size={20} />}
                                                                                style={{ position: 'absolute', top: '5px', right: '5px', }}
                                                                            /> */}
                                                                            </Button>
                                                                        </ColorPicker>

                                                                        : <></>

                                                                }

                                                                <Button
                                                                    onClick={firstColor != secondColor ? () => addSecondColor(firstColor) : () => addSecondColor(lighter(firstColor, 0.5))}
                                                                    id="add-new-color-button" icon={firstColor != secondColor ? <FaMinus size={20} /> : <IoMdAdd />}></Button>



                                                            </div>

                                                        </div>




                                                        {/* <Upload

                                                        onChange={handleCustomRequest}
                                                        showUploadList={false} // Oculta la lista de archivos subidos
                                                        beforeUpload={() => false} // Evita la carga automática de archivos
                                                    >

                                                        <CustomButton icon={<FaArrowUp />} text={'Subir'} />

                                                    </Upload> */}
                                                    </div>

                                            }
                                        </Col>
                                    )
                                }

                                {
                                    location === 'date' && (
                                        <Col
                                            className={`gc-content-container`}>

                                            <Row style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                                            }}>

                                                <Button
                                                    onClick={() => handleFlexDirection(!datePosition)}
                                                    icon={<HiSwitchVertical />} id={datePosition ? "build-cover-date-buttons" : "build-cover-date-buttons--active"}>Invertir</Button>

                                                <Button
                                                    onClick={invitation.cover.date ? () => onChangeDate(null) : () => onChangeDate(dayjs('2030-01-01'))}
                                                    icon={invitation.cover.date ? <BiHide /> : <BiShow />}
                                                    id={invitation.cover.date ? "build-cover-date-buttons" : "build-cover-date-buttons--active"}>{
                                                        invitation.cover.date ? 'Ocultar' : 'Mostrar'
                                                    }</Button>

                                            </Row>


                                            <Col className='gc-content-container-module'>
                                                <p className='gc-content-label'>Fecha del evento</p>

                                                <DatePicker
                                                    className='gc-date-picker'
                                                    style={{
                                                        marginBottom: datePosition ? '0px' : '5px',
                                                    }}
                                                    onChange={onChangeDate}
                                                    value={invitation.cover.date ? dayjs(invitation.cover.date.split('T')[0]) : null}
                                                />
                                            </Col>

                                            {/* <Col className='gc-content-container-module' style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                                                position: 'relative'
                                            }}>
                                                <p className='gc-content-label' style={{
                                                    width: '100%'
                                                }}>{types[currentTimer].name}</p>

                                                <div style={{
                                                    marginTop: '10px', ...types[currentTimer].content,


                                                }}>
                                                    <CountdownDev color={invitation.cover.color} colorPalette={invitation.cover.palette} dev={true} targetDate={invitation.cover.date} theme={invitation.generals.theme} font={invitation.generals.font} fontWeight={200} />


                                                </div>

                                                <Button
                                                    onClick={handlePlus}
                                                    id='switch-timer-button'
                                                    icon={<FaArrowRightLong size={20} />}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '0px',
                                                        top: '45%',
                                                        // transform: 'translateY(-50%)'
                                                    }} />

                                                <Button
                                                    onClick={handleLess}
                                                    icon={<FaArrowLeftLong size={20} />}
                                                    id='switch-timer-button'
                                                    style={{
                                                        position: 'absolute',
                                                        left: '0px',
                                                        top: '45%',
                                                        // transform: 'translateY(-50%)'
                                                    }} />



                                            </Col> */}


                                            {/* <p className='gc-content-label'>Color</p>

                                            <ColorPicker

                                                value={timerColor}
                                                onChange={(e) => onChnageTimerColor(e)}
                                            >
                                                <Button

                                                    style={{
                                                        backgroundColor: timerColor,
                                                        color: buttonsColorText(timerColor ? timerColor.toString() : '#000000')

                                                    }}
                                                    id='cover-pick-title-color'>
                                                    {timerColor}
                                                </Button>
                                            </ColorPicker> */}

                                        </Col>

                                    )
                                }










                            </div>

                            {/* {
                                settingsMode ?
                                    <div className='generals-card-container'>

                                        <Row className='gc-title-cta-buttons-container'>
                                            <p className='gc--title'>Configuración del título</p>

                                            <Row className='gc-cta-buttons-container'>

                                            </Row>
                                        </Row>

                                        <Col className='gc-content-container'>


                                            <p className='gc-content-label'>Tipo de letra</p>

                                            <Select

                                                value={invitation.generals.font}
                                                onChange={(e) => handleFont(e)}
                                                style={{ width: '100%', fontFamily: invitation.generals.font, }}>
                                                {fonts.map((font, index) => (
                                                    <Option style={{ fontFamily: font }} key={index} value={font}>{font}</Option>
                                                ))}

                                            </Select>

                                            <Col style={{
                                                width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column',
                                                marginTop: '10px'
                                            }}>
                                                <p className='gc-content-label'>Tamaño</p>

                                                <Slider
                                                    style={{ width: '95%', }}
                                                    min={2}
                                                    max={10}
                                                    step={0.01}
                                                    onChange={(e) => setInvitation(prevInvitation => ({
                                                        ...prevInvitation,
                                                        cover: {
                                                            ...prevInvitation.cover,
                                                            fontSize: e ? e : prevInvitation.cover.fontSize,
                                                        },
                                                    }))}
                                                    // onChange={onChange}
                                                    value={invitation.cover.fontSize}
                                                />

                                                <Row style={{
                                                    width: '100%', display: 'flex', alignItems: 'center',
                                                    justifyContent: 'space-between', flexDirection: 'row'
                                                }}>
                                                    <Col style={{
                                                        width: '48%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'
                                                    }}>

                                                        <p className='gc-content-label'>Opacidad</p>


                                                        <Slider
                                                            style={{ width: '95%' }}
                                                            // trackStyle={"#000"}
                                                            // handleStyle={{ borderColor: 'red' }}
                                                            min={0.1}
                                                            max={1}
                                                            step={0.01}
                                                            onChange={(e) => setInvitation(prevInvitation => ({
                                                                ...prevInvitation,
                                                                cover: {
                                                                    ...prevInvitation.cover,
                                                                    opacity: e ? e : prevInvitation.cover.opacity,
                                                                },
                                                            }))}
                                                            // onChange={onChange}
                                                            value={invitation.cover.opacity}
                                                        />

                                                    </Col>

                                                    <Col style={{
                                                        width: '48%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'
                                                    }}>
                                                        <p className='gc-content-label'>Grosor</p>



                                                        <Slider
                                                            style={{ width: '95%' }}
                                                            // trackStyle={"#000"}
                                                            // handleStyle={{ borderColor: 'red' }}
                                                            min={100}
                                                            max={1000}
                                                            step={100}

                                                            // onChange={(e) => console.log(e)}

                                                            onChange={(e) => setInvitation(prevInvitation => ({
                                                                ...prevInvitation,
                                                                cover: {
                                                                    ...prevInvitation.cover,
                                                                    fontWeight: e ? e : prevInvitation.cover.fontWeight,
                                                                },
                                                            }))}

                                                            value={invitation.cover.fontWeight}
                                                        />

                                                    </Col>
                                                </Row>

                                               



                                            </Col>

                                            <p className='gc-content-label'>Color</p>


                                            <ColorPicker
                                                value={invitation.cover.color}
                                                onChange={(e) => onChnageTitleColor(e)}
                                            >
                                                <Button

                                                    style={{
                                                        backgroundColor: invitation.cover.color,
                                                        color: buttonsColorText(invitation.cover.color ? invitation.cover.color.toString() : '#000000')

                                                    }}
                                                    id='cover-pick-title-color'>
                                                    {invitation.cover.color}
                                                </Button>
                                            </ColorPicker>

                                        </Col>

                                    </div>
                                    : <></>
                            } */}

                            {/* {
                                imageMode ?
                                    <div className='generals-card-container'>

                                        <Row className='gc-title-cta-buttons-container'>
                                            <p className='gc--title '>Portada</p>
                                        </Row>


                                       

                                    </div>
                                    : <></>
                            } */}

                        </Row >

                    </div >

                    : <></>
            }

        </>
    )
}






