import React, { useContext, useEffect, useState, } from 'react'
import { Button, Dropdown, Empty, Layout, Progress, Row, message } from 'antd';
import { storage } from '../../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import {
    IoMdAdd, FaRegCalendarAlt, LuBadgeHelp, IoMdOpen, formatDate, formatDateShort, IoMdSettings, IoArrowRedoSharp,
    FaRegCopy, TiThMenu, CgOptions, TbExternalLink, PiLinkBold, FaLock, FaUnlockAlt, HiLockClosed,
    IoMdArrowRoundForward, FiCopy, load, PiMonitorPlayBold,
    HiLockOpen, MdOutlineTimeline, MdDevices, MdBubbleChart,
    images,
    images_modules,
    decoration
} from '../../helpers'
import { Link } from 'react-router-dom';
import { useInvitation } from '../../hooks';
import { getAllInvitations, getAllUserInvitations, getAllUserTags, getInvitationbyID } from '../../services';
import { appContext } from '../../context';
import { Login } from '../../components/land-page/Login';
import { CustomButton } from '../../components/CustomButton';
import { NewInvitationDrawer } from '../../components/NewInvitationDrawer';
import { FooterMobile, HeaderBuild } from '../Build';
import justWeb from '../../assets/just_web.svg'
import { useLocation } from 'react-router-dom';
import { FaLessThanEqual } from 'react-icons/fa';

const { Content } = Layout;
const items = [
    {
        key: '1',
        label: (
            <a >
                Eliminar Invitacion
            </a>
        ),
    },

];

const desginCards = [
    {
        icon: <MdBubbleChart />,
        title: "Personalización Total",
        text: "Elige entre una amplia variedad de paletas de colores y tipografías, y ajusta cada elemento de la invitación para reflejar perfectamente el tema de tu evento.",
        image: images.itinerary,
    },
    {
        icon: <MdDevices />,
        title: "Flexibilidad en el Diseño",
        text: "Reorganiza los elementos a tu gusto, cambia imágenes, colores, y más. La libertad creativa está en tus manos para hacer que cada invitación sea única.",
        image: images.gallery,
    },
    {
        icon: <MdOutlineTimeline />,
        title: "Previsualización en Tiempo Real",
        text: "Ve cómo se verá tu invitación a medida que la diseñas. Con la previsualización en tiempo real, puedes hacer ajustes instantáneos y asegurarte de que todo esté perfecto antes de enviarla.",
        image: images.settings,
    }
]

const baseProd = "https://www.iattend.mx"
const baseDev = "http://localhost:3000";

export const ContentInvitations = ({ setMode, setCurrentInvitation, mode, saved, setOpenLogin, setSaved }) => {

    // getAllInvitations
    const { response, loading, error, operation } = useInvitation()
    const [myInvitations, setMyInvitations] = useState(null)
    const [visible, setVisible] = useState(false)
    const { logged, user, setOnDate, date } = useContext(appContext)
    const [loader, setLoader] = useState(false)
    const [currentPosition, setCurrentPosition] = useState(0)
    const [isVisible, setIsVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const { pathname } = useLocation();



    const getTimeUntilFutureDate = (futureDate) => {
        const currentDate = new Date();
        const targetDate = new Date(futureDate);

        // Calcular la diferencia en milisegundos
        const diffInMilliseconds = targetDate - currentDate;

        // Calcular la diferencia en días
        const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

        if (diffInDays > 3) {
            return `${diffInDays} días`;
        } else {
            // Calcular la diferencia en horas si faltan 3 días o menos
            const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
            return `${diffInHours} horas`;
        }
    }

    const refreshInvitations = () => {
        if (logged && user) {
            user.uid != "66a57fd93d724e3f40549da0" ?
                getAllUserInvitations(operation, user.uid)
                : getAllUserTags(operation, user.uid)
            setLoader(true)
        }

    }

    const copyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            message.success('Link copiado')
        } catch (err) {
            console.error('Error al copiar el texto: ', err);
        }
    };

    const handleMoode = (mode, id) => {
        setMode(mode)
        setCurrentInvitation(id)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    useEffect(() => {
        if (logged && user) {
            user.uid != "66a57fd93d724e3f40549da0" ?
                getAllUserInvitations(operation, user.uid)
                : getAllUserTags(operation, user.uid)
            setLoader(true)
        } else {
            setLoader(false)
        }

    }, [])

    useEffect(() => {
        if (logged && user) {
            user.uid != "66a57fd93d724e3f40549da0" ?
                getAllUserInvitations(operation, user.uid)
                : getAllUserTags(operation, user.uid)
            setLoader(true)
        } else {
            setLoader(false)
        }

    }, [logged])


    useEffect(() => {
        if (response) {
            if (response.data.ok) {
                setMyInvitations(response.data.data)
                setLoader(false)

            } else {
                message.error('Error trayendo las invitaciones')
            }
        }
    }, [response])

    useEffect(() => {
        setOnDate(new Date())
        setIsVisible(true)
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const margin = 0; // Ajusta este valor según tu preferencia


            if (currentScrollPos < margin) {
                setIsVisible(false);
            } else {
                setIsVisible(prevScrollPos > currentScrollPos);

                setPrevScrollPos(currentScrollPos);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);


    return (

        <>
            <Layout
                // className='responsive-web'
                style={{
                    position: 'relative',
                    alignItems: 'center', justifyContent: 'center',
                    backgroundColor: '#FFF',
                    // height: '100vh'
                }}>
                <HeaderBuild position={'invitations'} setOpenLogin={setOpenLogin} isVisible={isVisible} />
                <Layout className='scroll-invitation build-invitation-layout responsive-web' style={{
                    // paddingBottom: '30px',
                    marginTop: '80px'
                }} >
                    {
                        loader ?
                            <div style={{
                                height: '80vh', display: 'flex', alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%'
                                // backgroundColor: lighter(MainColor, 0.9)
                            }}>
                                <img src={load} style={{
                                    width: '10%'
                                }} />
                            </div>


                            : <Content className='invitations-main-content'>
                                {
                                    logged ?
                                        <div style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                                            position: 'relative', padding: '10px',
                                            width: '100%', maxWidth: '1480px', position: 'relative'

                                        }}>
                                            <div className='inv-title-cta-filter'>
                                                <h2 className='module--title inv-title'>Mis Invitaciones</h2>

                                                {/* <div className='inv-filter'></div> */}

                                                <CustomButton
                                                    onClick={() => setVisible(true)}
                                                    icon={<IoMdAdd />} text={'Nueva Invitación'} style={{
                                                        margin: '30px 0'
                                                    }} styleText={{ fontSize: '20px', marginLeft: '5px', fontWeight: 700 }} />

                                            </div>

                                            <div className='inv-invitations-container'>
                                                {
                                                    load ? (
                                                        myInvitations?.length > 0 ? (
                                                            myInvitations.map((invitation) => (
                                                                <div
                                                                    key={invitation._id}
                                                                    className='invitation-container'
                                                                >
                                                                    <div className='invitation-image-container'>
                                                                        {
                                                                            invitation.cover.featured_prod ? (
                                                                                <img src={invitation.cover.featured_prod} alt="Featured product" />
                                                                            ) : (
                                                                                <div
                                                                                    className='background-color'
                                                                                    style={{
                                                                                        background: invitation.cover.background,
                                                                                        opacity: 0.85,
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                        <h1
                                                                            style={{
                                                                                fontFamily: invitation.cover.image,
                                                                                fontWeight: invitation.cover.fontWeight,
                                                                                color: invitation.cover.color,
                                                                            }}
                                                                            className='invitation-image-title'
                                                                        >
                                                                            {invitation.cover.title}
                                                                        </h1>
                                                                    </div>

                                                                    <div className='invitation-settings-container'>
                                                                        <Row className='inv-status-container'>
                                                                            <a className='gc--title label'>
                                                                                {invitation.generals.eventName}
                                                                            </a>
                                                                        </Row>
                                                                        <div className='icon-deadline-container'>
                                                                            <Button
                                                                                disabled={!invitation.active}
                                                                                id='gc-cta-buttons'
                                                                                onClick={() =>
                                                                                    copyToClipboard(`${baseProd}/${invitation.label}/${invitation.generals.eventName}`)
                                                                                }
                                                                                type='ghost'
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                }}
                                                                                icon={<PiLinkBold size={20} />}
                                                                            />
                                                                            <Link
                                                                                to={`/${invitation.label}/${invitation.generals.eventName}`}
                                                                                target='_blank'
                                                                            >
                                                                                <Button
                                                                                    disabled={!invitation.active}
                                                                                    id='gc-cta-buttons'
                                                                                    type='ghost'
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                    }}
                                                                                    icon={<TbExternalLink size={20} />}
                                                                                />
                                                                            </Link>
                                                                        </div>
                                                                    </div>

                                                                    <div className='invitation-info-container'>
                                                                        <div className='inv-date-progress-container'>
                                                                            {
                                                                                invitation.active
                                                                                    ?
                                                                                    <div className='inv-info-col'>
                                                                                        <span className='inv-info-col-label'>Tiempo disponible</span>
                                                                                        <div className='icon-deadline-container'>
                                                                                            <span>{getTimeUntilFutureDate(invitation.cover.date)}</span>
                                                                                            <div
                                                                                                className='state-dot-container'
                                                                                                style={{ marginLeft: '5px' }}
                                                                                            >
                                                                                                {/* {
                                                                                    invitation.active === null ? (
                                                                                        <div className='state-dot--inactive--waiting'></div>
                                                                                    ) : invitation.active ? (
                                                                                        <div className='state-dot--active'></div>
                                                                                    ) : (
                                                                                        <div className='state-dot--inactive'></div>
                                                                                    )
                                                                                } */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className='inv-info-col'>
                                                                                        <span className='inv-info-col-label'>Esperando activación</span>
                                                                                        <div className='icon-deadline-container'>
                                                                                            <span>--- / ---</span>

                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>

                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                flexDirection: 'row',
                                                                                width: '100%',
                                                                            }}
                                                                        >
                                                                            {
                                                                                invitation.active ?
                                                                                    <Button
                                                                                        onClick={() =>
                                                                                            handleMoode('on-edit', invitation._id)
                                                                                        }
                                                                                        id='ic-edit-button'
                                                                                    >
                                                                                        Ver más
                                                                                    </Button> :
                                                                                    <Button
                                                                                        disabled
                                                                                        // onClick={() =>
                                                                                        //     handleMoode('on-edit', invitation._id)
                                                                                        // }
                                                                                        id='ic-waiting-button'
                                                                                    >
                                                                                        Ver más
                                                                                    </Button>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    marginTop: '100px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <Empty style={{ marginTop: '50px' }} description={false} />
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                marginTop: '150px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <img
                                                                src={load}
                                                                style={{
                                                                    width: '5%',
                                                                }}
                                                                alt="Loading"
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className='shadow--white-right' />
                                            <div className='shadow--white-left' />
                                        </div>

                                        :
                                        <div style={{
                                            width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'flex-start',
                                            paddingTop: '100px', justifyContent: 'center'
                                        }}>
                                            <Login position={'land-page'} setOpenLogin={setOpenLogin} />
                                        </div>
                                }


                            </Content>
                    }



                </Layout>

                <Layout
                    className='responsive-mobile'
                    style={{
                        height: '100vh', backgroundColor: '#FFFFFF',
                        width: '100%'
                    }}
                >

                    <img src={justWeb} style={{
                        objectFit: 'cover', width: '80%'
                    }} />

                </Layout >
                <FooterMobile position={'invitations'} setOpenLogin={setOpenLogin} isVisible={isVisible} />


            </Layout >

            {
                logged && <NewInvitationDrawer visible={visible} setVisible={setVisible} refreshInvitations={refreshInvitations} />
            }

        </>

    )
}
