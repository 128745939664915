import React, { useEffect, useState } from 'react'
import { Col } from 'antd'
import { CustomCard, } from './Steps'
import { darker, extratImageID, lighter } from '../../helpers/functions'
import { FaChurch } from "react-icons/fa";
import { icons } from '../../helpers/images';
import { Ceremony, CeremonyIcon, DanceIcon, DinnerIcon, Reception, ReceptionIcon } from './Logos';
import { LuBadgeHelp } from 'react-icons/lu';
import { iconsItinerary } from '../../helpers';


export const Card = ({
    steps, MainColor, theme, font, dev, colorPalette, background
}) => {

    const [stepsItems, setStepsItems] = useState(steps)

    const handleSelectedCard = (id) => {
        const updatedCards = stepsItems.map((card) => card.id === id ? { ...card, active: !card.active } : card)
        setStepsItems(updatedCards)
    }


    useEffect(() => {
        setStepsItems(steps)
    }, [steps])


    const renderIcon = (index) => {
        // console.log(index)
        const icon = iconsItinerary.find(icon => icon.index === index);
        if (icon) {
            const IconComponent = icon.value;
            return <IconComponent size={35} style={{ color: colorPalette.accent }} />;
        }
        return <LuBadgeHelp size={35} style={{ color: colorPalette.accent }} />;
    };

    // const background = `linear-gradient(to bottom, ${(colorPalette.secondary)}80 0%, ${(colorPalette.secondary)} 100%)`




    return (
        <>
            {
                stepsItems.map((item, index) => (
                    <div
                        key={index}
                        className={`${dev ? 'card-container-dev' : 'card-container'} ${item.active ? 'card-container-active' : ''}`}
                        style={{
                            background: background ? colorPalette.primary : colorPalette.secondary,
                            height: item.active ? 'auto' : '100px',
                            marginBottom: index < stepsItems.length ? '20px' : '0px',
                            justifyContent: item.active ? 'center' : 'flex-start',
                            flexDirection: item.active ? 'column' : 'row',
                            border: 'none',
                            boxShadow: '0px 0px 0px rgba(0,0,0,0)'
                        }}
                    >
                        {item.active ? (
                            <CustomCard dev={dev} onClose={handleSelectedCard} item={item} MainColor={MainColor} theme={theme} font={font} colorPalette={colorPalette} />
                        ) : (
                            <>
                                <div
                                    className="card-icon-wrapper"
                                    onClick={() => handleSelectedCard(item.id)}
                                >
                                    <div
                                        className="card-icon"
                                        style={{
                                            backgroundColor: background ? colorPalette.secondary : colorPalette.primary,
                                            border: 'none',
                                        }}
                                    >
                                        {item.image ? renderIcon(parseInt(item.image)) : <LuBadgeHelp size={35} style={{ color: colorPalette.accent }} />}
                                    </div>
                                </div>
                                <Col
                                    className="card-content"
                                    onClick={() => handleSelectedCard(item.id)}
                                    style={{
                                        color: colorPalette.accent
                                    }}
                                >
                                    <p className={dev ? "card-title-dev" : "card-title"} style={{ fontFamily: font }}>{item.name}</p>
                                    <p className={dev ? "card-time-dev" : "card-time"} style={{ fontFamily: font }}>{item.time}</p>
                                    <p className={dev ? "card-subname-dev" : "card-subname"} style={{ fontFamily: font }}>{item.subname}</p>
                                </Col>
                            </>
                        )}
                    </div>
                ))
            }
        </>
    )
}
