import { Button, Input, Row, Switch } from 'antd'


import { BiSolidColorFill, LuSeparatorHorizontal, TbEyeClosed } from '../../helpers';
import { appContext } from '../../context';
import { useContext } from 'react';



export const BuildQuote = ({ invitation, setInvitation, setSaved, onDesign }) => {

    // const { onDesign } = useContext(appContext)

    const onChangeQuote = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            quote: {
                ...prevInvitation.quote,
                description: e ? e.target.value : prevInvitation.quote.description,
            },
        }));
        setSaved(false)
    }

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            quote: {
                ...prevInvitation.quote,
                active: e,
            },
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            quote: {
                ...prevInvitation.quote,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            quote: {
                ...prevInvitation.quote,
                separator: e,
            },
        }));
        setSaved(false)
    }


    return (
        <>
            {
                invitation ?
                    <div className='build-cover-main-container'>


                        <h2 className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            style={{
                                width: '100%',
                            }}
                        >Cita</h2>

                        {
                            onDesign ?
                                <p className='module--description' >
                                    Elige una cita significativa que refleje el espíritu o el motivo de tu evento. Este módulo te permite integrar palabras que inspiren o conmuevan, añadiendo un toque personal y profundo a tu invitación.</p>
                                : <div className='module--description' style={{ marginBottom: '30px' }} />
                        }




                        <Row
                            style={{
                                alignItems: 'flex-start',
                                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column',
                                width: '100%'
                            }}>

                            <Row style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row',
                                width: '100%', marginBottom: '10px'
                            }}>


                                {
                                    invitation.quote.active && (
                                        <>
                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeSeparator(!invitation.quote.separator)}
                                                id={`gc-cta-buttons${invitation.quote.separator ? '--selected' : ''}`}
                                                icon={<LuSeparatorHorizontal size={25} />} />

                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeBackground(!invitation.quote.background)}
                                                id={`gc-cta-buttons${invitation.quote.background ? '--selected' : ''}`}
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                                icon={<BiSolidColorFill size={25} />} />

                                        </>
                                    )
                                }



                                <Switch
                                    value={invitation.quote.active}
                                    onChange={handleActive} />

                            </Row>

                            {
                                invitation.quote.active ?

                                    <Input.TextArea
                                        value={invitation.quote.description}
                                        autoSize={{ minRows: 10, maxRows: 20 }}
                                        onChange={onChangeQuote}
                                        className='gc-input-text'
                                        style={{
                                            width: '100%', borderRadius: '15px',
                                            minWidth: '290px', padding: '10px 15px'
                                        }} />
                                    : <div />

                            }






                        </Row>

                        {
                            !invitation.quote.active && (
                                <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                            )
                        }

                    </div>
                    : <></>
            }
        </>
    )
}


