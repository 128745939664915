import React, { useContext, useEffect, useState, } from 'react'
import { Layout, Select } from 'antd';
import { InvitationTest } from '../../pages';
import { appContext } from '../../context';


const { Content } = Layout;
const { Option } = Select;

const screens = [
    // {
    //     name: 'iPhone 15 & 15 Pro MAX',
    //     width: '430px',
    //     height: '932px'
    // },

    {
        name: 'iPhone',
        width: '340px',
        height: '80vh'
    },

    {
        name: 'iPad',
        width: '900px',
        height: '80vh'
    },

];

export const ContentBuild = ({ positionY, setPositionY, invitation, coverUpdated, onTry }) => {

    const { setOnDesigning } = useContext(appContext)

    useEffect(() => {
        setPositionY(positionY)
    }, [coverUpdated])

    const [size, setSize] = useState(screens[0])



    const handleSizeChange = (value) => {
        const selectedScreen = screens.find(screen => screen.name === value);
        setSize({
            name: selectedScreen.name,
            width: selectedScreen.width,
            height: selectedScreen.height
        });
    };

    useEffect(() => {
        if (size) {
            size.name === 'iPhone' ? setOnDesigning(true)
                : setOnDesigning(false)
        }
    }, [size])





    return (

        invitation && positionY && !coverUpdated ?
            <Content className='device-container'
                style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    flexDirection: 'column',
                    // width: `calc(${`${size.width}px`} + 50px)`,
                    width: `auto`,
                    // minWidth: `auto`,
                    padding: '10px',
                    position: 'relative',
                    // border: '1px solid green'


                }}
            >
                {/* {
                    !onTry && (
                        <Select
                            dropdownClassName="custom-dropdown"
                            // dropdownStyle={{ backgroundColor: '#202020', }}
                            style={{
                                width: '200px', position: 'absolute', top: '30px', zIndex: 90,
                                left: '50%', transform: 'translateX(-50%)',
                            }}
                            value={size.name}
                            onChange={handleSizeChange}
                        >
                            {screens.map((screen, index) => (
                                <Option key={index} value={screen.name}>{screen.name}</Option>
                            ))}
                        </Select>
                    )
                } */}


                <div
                    className='scroll-invitation'
                    style={{
                        height: onTry ? '75vh' : '80vh',
                        width: onTry ? 'calc(90vh / 2.5)' : 'calc(100vh / 2.5)',
                        backgroundColor: '#000',
                        minHeight: '620px',
                        minWidth: '300px',
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        // border: '1px solid #EBEBEB',
                        borderRadius: '44px',
                        boxShadow: '0px 0px 16px rgba(0,0,0,0.15)',
                        transition: 'all 0.3s ease'
                    }}>






                    <InvitationTest positionY={positionY} invitation={invitation} size={size} />

                </div>

            </Content >
            : <></>


    )
}
