import { Button, Layout, Popconfirm, message, Row } from 'antd'

import React, { useContext, useEffect, useState } from 'react'
import { HeaderBuild, ContentBuild, SiderBuild, FooterBuild, HeaderDev } from '../layouts/Build'

import { editInvitation, editTag, getInvitationbyID, getTagByID, getTagID } from '../services'
import { useInvitation } from '../hooks'
import { appContext } from '../context'

import {
    MdCardGiftcard, MdFullscreen, PiDress, PiHandWaving, RiParentLine, generateGuestId,
    FiFeather, FaTasks, LuNewspaper, GrGallery, IoSettingsOutline, load, lighter, darker,
    IoCloseSharp
} from '../helpers'

import { storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { calc } from 'antd/es/theme/internal'
import { SiderTry } from '../layouts/try/Sider'
import { InvitationPage } from './InvitationPage'


export const BuildTry = ({ invitationID, setMode, saved, setSaved, mode, device }) => {



    const size = 20
    const styleIcon = {
        // marginBottom: '5px'
    }
    const buttons = [
        {
            icon: <IoSettingsOutline size={size} style={styleIcon} />,
            action: null,
            name: 'Generales',
            type: 'generals',
            value: 1,
            position: 0,
        },
        {
            icon: <MdFullscreen size={size} style={styleIcon} />,
            action: null,
            name: 'Portada',
            type: 'cover',
            value: 2,
            position: 0,
        },
        {
            icon: <PiHandWaving size={size} style={styleIcon} />,
            action: null,
            name: 'Bienvenida',
            type: 'greeting',
            value: 3,
            position: 950,
        },
        {
            icon: <RiParentLine size={size} style={styleIcon} />,
            action: null,
            name: 'Personas',
            type: 'family',
            value: 4,
            position: 1375,
        },
        {
            icon: <FiFeather size={size} style={styleIcon} />,
            action: null,
            name: 'Cita',
            type: 'quote',
            value: 5,
            position: 1750,
        },
        {
            icon: <FaTasks size={size} style={styleIcon} />,
            action: null,
            name: 'Itinerario',
            type: 'itinerary',
            value: 6,
            position: 2100,
        },
        {
            icon: <PiDress size={size} style={styleIcon} />,
            action: null,
            name: 'Dresscode',
            type: 'dresscode',
            value: 7,
            position: 2750,
        },
        {
            icon: <MdCardGiftcard size={size} style={styleIcon} />,
            action: null,
            name: 'Regalos',
            type: 'gifts',
            value: 8,
            position: 3050,
        },
        {
            icon: <LuNewspaper size={size} style={styleIcon} />,
            action: null,
            name: 'Avisos',
            type: 'notices',
            value: 9,
            position: 3550,
        },
        {
            icon: <GrGallery size={size} style={styleIcon} />,
            action: null,
            name: 'Galería',
            type: 'gallery',
            value: 10,
            position: 4500,
        }
    ]


    const buttonsTry = [
        {
            icon: <IoSettingsOutline size={size} style={styleIcon} />,
            action: null,
            name: 'Generales',
            type: 'generals',
            value: 1,
            position: 0,
        },
        {
            icon: <MdFullscreen size={size} style={styleIcon} />,
            action: null,
            name: 'Portada',
            type: 'cover',
            value: 2,
            position: 1,
        },
        {
            icon: <PiHandWaving size={size} style={styleIcon} />,
            action: null,
            name: 'Bienvenida',
            type: 'greeting',
            value: 3,
            position: 2,
        },
        // {
        //     icon: <RiParentLine size={size} style={styleIcon} />,
        //     action: null,
        //     name: 'Personas',
        //     type: 'family',
        //     value: 4,
        //     position: 1375,
        // },
        {
            icon: <FiFeather size={size} style={styleIcon} />,
            action: null,
            name: 'Cita',
            type: 'quote',
            value: 5,
            position: 3,
        },
        // {
        //     icon: <FaTasks size={size} style={styleIcon} />,
        //     action: null,
        //     name: 'Itinerario',
        //     type: 'itinerary',
        //     value: 6,
        //     position: 2100,
        // },
        {
            icon: <PiDress size={size} style={styleIcon} />,
            action: null,
            name: 'Dresscode',
            type: 'dresscode',
            value: 7,
            position: 4,
        },
        // {
        //     icon: <MdCardGiftcard size={size} style={styleIcon} />,
        //     action: null,
        //     name: 'Regalos',
        //     type: 'gifts',
        //     value: 8,
        //     position: 3050,
        // },
        {
            icon: <LuNewspaper size={size} style={styleIcon} />,
            action: null,
            name: 'Avisos',
            type: 'notices',
            value: 9,
            position: 5,
        },
        // {
        //     icon: <GrGallery size={size} style={styleIcon} />,
        //     action: null,
        //     name: 'Galería',
        //     type: 'gallery',
        //     value: 10,
        //     position: 4500,
        // }
    ]

    const [positionY, setPositionY] = useState('cover')
    const [position, setPosition] = useState(1)
    const [invitation, setInvitation] = useState(null)
    const [loader, setLoader] = useState(false)
    const { response, operation } = useInvitation()
    const { user, setMainColor, setTheme, setFont, setOnDate, setOnDesigning, setColorPalette, logged } = useContext(appContext)
    const [coverUpdated, setCoverUpdated] = useState(false)
    const [copy, setCopy] = useState(null)
    const [currentSection, setCurrentSection] = useState(2)
    // const [saved, setSaved] = useState(true)


    const handleClick = (item) => {
        setCurrentSection(item.value)
        setPositionY(item.type)
        setPosition(item.position)
    }



    useEffect(() => {
        setLoader(true)
        getTagByID(operation, "66a58558560f57ceb4947337")

    }, [])


    useEffect(() => {

        if (response) {
            if (response.data.ok) {
                switch (response.data.msg) {
                    case "Get invitation By Id":
                        const inv = response.data.data
                        setMainColor(inv.generals.color)
                        if (inv.generals.palette) {
                            setColorPalette({
                                primary: inv.generals.palette.primary,
                                secondary: inv.generals.palette.secondary,
                                accent: inv.generals.palette.accent,
                                buttons: inv.generals.palette.buttons,
                            })
                        } else {
                            setColorPalette({
                                primary: lighter('#000000', 0.9),
                                secondary: lighter('#000000', 0.8),
                                accent: darker('#000000', 0.4),
                                buttons: lighter('#000000', 0.5)
                            })
                        }

                        setTheme(inv.generals.theme)
                        setFont(inv.generals.font)
                        setInvitation(inv)
                        setLoader(false)
                        break;

                    case "Invitation updated":
                        message.success("Invitación actualizada")
                        const updated = response.data.updatedItem
                        setMainColor(updated.generals.color)
                        if (updated.generals.palette) {
                            setColorPalette({
                                primary: updated.generals.palette.primary,
                                secondary: updated.generals.palette.secondary,
                                accent: updated.generals.palette.accent,
                                buttons: updated.generals.palette.buttons
                            })
                        } else {
                            setColorPalette({
                                primary: lighter('#000000', 0.9),
                                secondary: lighter('#000000', 0.8),
                                accent: darker('#000000', 0.4),
                                buttons: '#000000'
                            })
                        }
                        setTheme(updated.generals.theme)
                        setFont(updated.generals.font)
                        setInvitation(updated)
                        setLoader(false)
                        break;

                    default:
                        break;
                }

            }
        }



    }, [response])

    useEffect(() => {
        if (invitation) {

            console.log(invitation)

            const newCopy = {
                ...invitation,
                cover: {
                    ...invitation.cover,
                    featured_dev: invitation.cover.featured_prod
                },
                dresscode: {
                    ...invitation.dresscode,
                    images_dev: invitation.dresscode.images_prod
                },
                gallery: {
                    ...invitation.gallery,
                    gallery_dev: invitation.gallery.gallery_prod
                }

            }

            setCopy(newCopy)
            // localStorage.setItem('backup-storage', JSON.stringify(newCopy));
            setOnDate(new Date(invitation.cover.date))

        }
    }, [invitation])


    useEffect(() => {
        if (coverUpdated) {

            handleClick(buttons[0])

            setTimeout(() => {
                // setLoader(true)
                // getInvitationbyID(operation, invitationID)
                setCoverUpdated(false)
                handleClick(buttons[1])

            }, 500);
        }
    }, [coverUpdated])


    useEffect(() => {
        if (invitation) {
            console.log(invitation)
        }
    }, [invitation])




    return (

        <>

            <Layout
                className='responsive-web'
                style={{
                    position: 'relative',
                    alignItems: 'center', justifyContent: 'center',
                    backgroundColor: 'transparent',
                    // height: '100vh'
                }}>
                {/* <HeaderDev onSaveChanges={onSaveChanges} position={'invitations'} setMode={setMode} mode={mode} saved={saved} invitation={copy ? copy : null} /> */}
                <Layout className='scroll-invitation build-invitation-layout' style={{
                    backgroundColor: 'transparent'
                }} >


                    {
                        invitation ?

                            <div style={{
                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                                position: 'relative',
                                // height: 'calc(100vh * 0.98)',
                                padding: '20px',
                            }}>
                                <FooterBuild position={position} buttonsTry={buttonsTry} invitation={copy} saved={saved} setMode={setMode} buttons={buttons} currentSection={currentSection} handleClick={handleClick} onTry={true} />
                                <div style={{
                                    width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row',
                                    position: 'relative',
                                }}>

                                    {
                                        !device ?
                                            <>

                                                <SiderTry
                                                    setSaved={setSaved} saved={saved} mode={mode} setMode={setMode}
                                                    coverUpdated={coverUpdated} setCoverUpdated={setCoverUpdated} invitationID={invitationID} onTry={true}
                                                    buttons={buttons} currentSection={currentSection} setPositionY={setPositionY} positionY={positionY} invitation={copy} setInvitation={setCopy} />
                                                <ContentBuild coverUpdated={coverUpdated} positionY={positionY} setPositionY={setPositionY} invitation={copy} onTry={true} />
                                            </>
                                            :
                                            // <InvitationPage invitationID={copy._id} />
                                            <ContentBuild coverUpdated={coverUpdated} positionY={positionY} setPositionY={setPositionY} invitation={copy} onTry={true} />
                                    }

                                </div>



                            </div>

                            : <div style={{
                                height: '80vh', display: 'flex', alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%'
                                // backgroundColor: lighter(MainColor, 0.9)
                            }}>
                                <img src={load} style={{
                                    width: '10%'
                                }} />
                            </div>

                    }
                </Layout>

            </Layout >



        </>

    )
}
