import { Button, Input, Layout } from 'antd'
import React, { useEffect, useState } from 'react'
import GuestsPage from './GuestsPage'
import { HeaderShared } from '../layouts/Build'
import { FaLock } from 'react-icons/fa'
import { loginShare } from '../services/apiGuests'
import { useInvitation } from '../hooks'

export const ShareGuests = ({ invitationID }) => {

    const [mode, setMode] = useState(false)
    const [validated, setValidated] = useState(false)
    const [access, setAccess] = useState(null)
    const [usuario, setUsuario] = useState(null)
    const { response, operation } = useInvitation()

    const handleLogin = () => {
        loginShare(operation, invitationID, access)
    }

    useEffect(() => {
        if (response) {

            if (response.data.ok) {
                switch (response.data.msg) {
                    case "Valid user":
                        setValidated(true)
                        setUsuario(response.data.data.user)
                        const token = response.data.data.token
                        localStorage.setItem("token", token)
                        break;

                    default:
                        break;
                }



            }
        }
    }, [response])

    return (


        <>
            {
                !validated ?
                    <Layout style={{
                        width: '100%', height: '100vh'
                    }}>
                        <div className='locked-invitation-background'>
                            <div className='locked-invitation-container'>
                                <div className='locked-icon-container'>
                                    <FaLock />
                                </div>
                                <h1 className='locked-page-title'>Acceso a Panel de Administración de Invitaciones</h1>
                                <h2 className='locked-page-text'>Para acceder al panel de respuestas, ingrese su usuario y contraseña. Esto mantiene la seguridad y privacidad del evento. </h2>
                                <Input
                                    value={access}
                                    onChange={(e) => setAccess(e.target.value)}
                                    placeholder='Código de acceso' className='locked-input' />
                                <Button
                                    onClick={handleLogin}
                                    id="locked-access-button" >Continuar</Button>
                            </div>


                        </div>
                    </Layout>
                    :

                    <GuestsPage mode={mode} setMode={setMode} invitationID={invitationID} shared={true} user={usuario} />
            }
        </>

    )
}
