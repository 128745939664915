import React, { useContext, useEffect, useState, } from 'react'
import { Button, Image, Layout, Modal, } from 'antd';
import {
    load, PiMonitorPlayBold, MdOutlineTimeline, MdDevices, MdBubbleChart,
    images,
    images_modules,
    decoration
} from '../helpers'
import { appContext } from '../context';
import { BuildTry } from '../pages/BuildTry';
import { devModules } from '../helpers/invitation-types';
import { FooterMobile, HeaderBuild } from '../layouts/Build';
import { Login } from '../components/land-page/Login';
import { Link, useLocation } from 'react-router-dom';
import { MdPersonAdd, MdShare, MdVisibility } from 'react-icons/md';

const { Content } = Layout;

const desginCards = [
    {
        icon: <MdBubbleChart />,
        title: "Personalización Total",
        text: "Elige entre una amplia variedad de paletas de colores y tipografías, y ajusta cada elemento de la invitación para reflejar perfectamente el tema de tu evento.",
        image: images.itinerary,
    },
    {
        icon: <MdDevices />,
        title: "Flexibilidad en el Diseño",
        text: "Reorganiza los elementos a tu gusto, cambia imágenes, colores, y más. La libertad creativa está en tus manos para hacer que cada invitación sea única.",
        image: images.gallery,
    },
    {
        icon: <MdOutlineTimeline />,
        title: "Previsualización en Tiempo Real",
        text: "Ve cómo se verá tu invitación a medida que la diseñas. Con la previsualización en tiempo real, puedes hacer ajustes instantáneos y asegurarte de que todo esté perfecto antes de enviarla.",
        image: images.settings,
    }
]

const guestManagementCards = [
    {
        icon: <MdShare />,
        title: "Comparte tu Invitación",
        text: "Facilita la distribución de tu invitación digital con solo un clic, asegurándote de que todos tus invitados reciban la información a tiempo.",
        image: images.share_guests,
    },
    {
        icon: <MdPersonAdd />,
        title: "Gestión de Invitados",
        text: "Agrega, edita o elimina invitados fácilmente. Mantén tu lista actualizada y organizada para una gestión eficiente de tu evento.",
        image: images.new_guests,
    },
    {
        icon: <MdVisibility />,
        title: "Control de Visibilidad",
        text: "Decide quién puede ver tu invitación, asegurando la privacidad y el acceso solo a quienes tú decidas.",
        image: images.privacy_guests,
    }
]


export const DiscoverPage = ({ }) => {

    const { setOnDate, logged } = useContext(appContext)
    const [isVisible, setIsVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [loader, setLoader] = useState(false)
    const [currentPosition, setCurrentPosition] = useState(0)
    const [openLogin, setOpenLogin] = useState(false)
    const [saved, setSaved] = useState(true)
    const { pathname } = useLocation();

    useEffect(() => {
        setOnDate(new Date())
        setIsVisible(true)
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const margin = 0; // Ajusta este valor según tu preferencia


            if (currentScrollPos < margin) {
                setIsVisible(false);
            } else {
                setIsVisible(prevScrollPos > currentScrollPos);

                setPrevScrollPos(currentScrollPos);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (

        <>
            <Layout
                // className='responsive-web'
                style={{
                    position: 'relative',
                    alignItems: 'center', justifyContent: 'center',
                    backgroundColor: '#FFF',
                    // height: '100vh'
                }}>
                <HeaderBuild position={'discover'} setOpenLogin={setOpenLogin} isVisible={isVisible} />

                <Layout className='scroll-invitation build-invitation-layout' style={{
                    // paddingBottom: '30px',
                    marginTop: '80px'
                }} >
                    <Content className='invitations-main-content'>
                        <div className='discover-main-container' >
                            {/* <img src={decoration.yellow} className='decoration-yellow-mobile' /> */}
                            <img src={decoration.yellow} className='decoration-image-yellow'
                                style={{
                                    // border: '1px solid red'
                                }} />
                            <h2 className='not-logged-head' style={{
                                // border: '1px solid red'
                            }}>Imagina. Diseña. Comparte</h2>

                            <div className='test-build-invitation-container first-box'>

                                <div className='try-inv-icon-cont disable-icon'>
                                    <PiMonitorPlayBold />
                                </div>

                                <span className='try-inv-head'>Descubre lo fácil que es diseñar invitaciones personalizadas que capturan la esencia de tu evento</span>
                                <span className='try-inv-single discover-description'> Con nuestras herramientas intuitivas, puedes ajustar cada detalle a tu gusto, desde los colores hasta la tipografía, asegurando que tu invitación sea tan única como tu celebración.</span>
                                <div className='try-inv-buttons-container'>
                                    {
                                        devModules.map((item) => (
                                            <Button
                                                onClick={() => setCurrentPosition(item.index)}
                                                id='try-categorie-button'
                                                style={{
                                                    backgroundColor: currentPosition === item.index && '#DDDCDB'
                                                }}
                                            >
                                                {item.name}
                                            </Button>
                                        ))
                                    }
                                </div>


                                <div className='test-build-invitation-second-container' style={{

                                }}>
                                    <div className='try-dots-container'>
                                        <div className='try-dot accent-dot' />
                                        <div className='try-dot' />
                                        <div className='try-dot' />
                                    </div>
                                    <img src={images_modules[currentPosition]} className='module-image-example' style={{
                                        marginTop: '10px'
                                    }} />


                                </div>





                            </div>

                            <div className='try-inv-second-section' >
                                {
                                    desginCards.map((card) => (
                                        <div className='test-build-invitation-container test-build-small-card'>
                                            <div className='try-inv-icon-cont'>
                                                {card.icon}
                                            </div>

                                            <span className='try-inv-head-second' style={{
                                            }}>{card.title}</span>
                                            <span style={{
                                                fontSize: '14px'
                                            }} className='try-inv-single-second'>{card.text}</span>

                                            <div style={{
                                                width: '70%', height: '65%',
                                                position: 'absolute', bottom: -80,
                                                overflow: 'hidden', borderRadius: '8px', backgroundColor: '#FFF',
                                                padding: '10px'
                                            }}>
                                                <div style={{
                                                    width: '100%', height: '100%',
                                                    position: 'relative',
                                                    // overflow: 'hidden'
                                                }}>
                                                    <img src={card.image}
                                                        style={{
                                                            objectFit: 'cover', width: '100%',
                                                            // height: '100%',
                                                            // position: 'absolute', top: 0
                                                        }}
                                                    />

                                                </div>

                                            </div>

                                        </div>
                                    ))
                                }

                            </div>


                            <img src={decoration.start}

                                className='decoration-image-admin' />
                            <h2 className='not-logged-head long-text-head-nl' style={{
                                marginTop: '20px',
                                marginBottom: '20px',
                                textAlign: 'center',
                                lineHeight: '1'
                                // border: '1px solid red'
                            }}>Descubre como luce una invitación</h2>

                            <Link
                                to={`/wedding/test-1`}
                                target='_blank'
                            >
                                <Button
                                    style={{
                                        // border: '1px solid red'
                                    }}
                                    id="access-button"
                                // onClick={() => setOpenLogin(true)}
                                >
                                    VER INVITACIÓN
                                </Button>
                            </Link>






                            <div className='simulator-container' style={{
                                // border: '1px solid red'
                            }}>
                                <h2 className='not-logged-head' style={{
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    // border: '1px solid red'
                                }}>Crea tu primer invitación</h2>
                                <div className='test-build-invitation-container'>

                                    {/* <img src={images.looking} className='watching-image' /> */}
                                    {/* <img src={images.looking} className='watching-image' /> */}
                                    <div className='try-inv-icon-cont'>
                                        <PiMonitorPlayBold />
                                    </div>

                                    <span className='try-inv-head'>Explora Nuestro Simulador de Invitaciones</span>
                                    <span className='try-inv-single'>¿Curioso por ver cómo funciona? Prueba nuestro simulador interactivo y descubre lo fácil y divertido que es diseñar tus invitaciones. Ajusta colores, tipografías, imágenes y más, todo en tiempo real. ¡Crea tu primera invitación y sorpréndete con las posibilidades!</span>

                                    <div className='test-build-invitation-second-container' style={{
                                        width: '85%'
                                    }}>
                                        <div className='try-dots-container'>
                                            <div className='try-dot accent-dot' />
                                            <div className='try-dot' />
                                            <div className='try-dot' />
                                        </div>
                                        <BuildTry invitationID={"66a58558560f57ceb4947337"} setSaved={setSaved} device={false} />
                                        {/* <img src={images_modules[currentPosition]} className='module-image-example' /> */}


                                    </div>







                                </div>
                            </div>

                            <img src={decoration.admin} className='decoration-image-admin' style={{
                                // border: '1px solid red'
                            }} />
                            <h2 className='not-logged-head text-aling-head' style={{
                                // border: '1px solid red'
                            }}>Organiza tu lista de invitados</h2>


                            <div className='test-build-invitation-container first-box'>
                                <div className='try-inv-icon-cont disable-icon'>
                                    <PiMonitorPlayBold />
                                </div>

                                <span className='try-inv-head'>Gestiona tus Invitados de Manera Eficiente</span>
                                <span className='try-inv-single discover-description'>Administra fácilmente tu lista de invitados, controla quién puede ver tu invitación, y recibe confirmaciones de asistencia en tiempo real. Nuestra plataforma te ofrece todas las herramientas necesarias para que la gestión de tus invitados sea sencilla y efectiva.</span>

                                <div className='test-build-invitation-second-container try-inv-guests-box' style={{

                                }}>
                                    <div className='try-dots-container discover-description'>
                                        <div className='try-dot accent-dot' />
                                        <div className='try-dot' />
                                        <div className='try-dot' />
                                    </div>
                                    <Image src={images.admin_guests} className='module-image-example' style={{
                                        marginTop: '10px'
                                    }} />


                                </div>


                            </div>

                            <div className='try-inv-second-section' >
                                {
                                    guestManagementCards.map((card) => (
                                        <div className='test-build-invitation-container test-build-small-card'>
                                            <div className='try-inv-icon-cont'>
                                                {card.icon}
                                            </div>

                                            <span className='try-inv-head-second' style={{
                                            }}>{card.title}</span>
                                            <span style={{
                                                fontSize: '14px'
                                            }} className='try-inv-single-second'>{card.text}</span>

                                            <div style={{
                                                width: '70%', height: '65%',
                                                position: 'absolute', bottom: -80,
                                                overflow: 'hidden', borderRadius: '8px', backgroundColor: '#FFF',
                                                padding: '10px'
                                            }}>
                                                <div style={{
                                                    width: '100%', height: '100%',
                                                    position: 'relative',
                                                    // overflow: 'hidden'
                                                }}>
                                                    <img src={card.image}
                                                        style={{
                                                            objectFit: 'cover', width: '100%',
                                                            // height: '100%',
                                                            // position: 'absolute', top: 0
                                                        }}
                                                    />

                                                </div>

                                            </div>

                                        </div>
                                    ))
                                }

                            </div>

                            <img src={decoration.create}
                                style={{
                                    // border: '1px solid red'
                                }}
                                className='decoration-image-create' />
                            <h2 className='not-logged-head' style={{
                                marginTop: '20px',
                                marginBottom: '20px',
                                textAlign: 'center'
                                // border: '1px solid red'
                            }}>Comienza a crear hoy</h2>

                            <Link to={logged ? "/invitations" : "/login"}>
                                <Button
                                    style={{
                                        // border: '1px solid red'
                                    }}
                                    id="access-button"
                                // onClick={() => setOpenLogin(true)}
                                >
                                    COMENZAR A CREAR
                                </Button>
                            </Link>


                        </div>

                    </Content>


                    {/* <Modal
                        open={openLogin}
                        onClose={() => setOpenLogin(false)}
                        onCancel={() => setOpenLogin(false)}

                        footer={null} // Opcional: Elimina el footer del modal si no es necesario
                        style={{
                            top: 40,
                            display: 'flex',
                            alignItems: 'center', justifyContent: 'center',
                        }}
                    >
                        <Login position={'land-page'} setOpenLogin={setOpenLogin} />

                    </Modal> */}

                </Layout>

                <FooterMobile position={'discover'} setOpenLogin={setOpenLogin} isVisible={isVisible} />

            </Layout >

        </>

    )
}
