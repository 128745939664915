import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { InvitationPage, InvitationsPage, LandPage } from '../pages'
import { getAllInvitations } from '../services'
import { useInvitation } from '../hooks'
import { ShareGuests } from '../pages/ShareGuests'
import { PricingPage } from '../pages/PricingPage'
import { HelpPage } from '../pages/HelpPage'
import { DiscoverPage } from '../pages/DiscoverPage'
import { LoginPage } from '../pages/LoginPage'
import { PageNotFound } from '../pages/PageNotFound'
import { AdminPage } from '../pages/AdminPage'
import { AdminHOC } from './AdminHOC'



export const AppRouter = () => {

  const { response, loading, error, operation } = useInvitation()
  const [liveInvitations, setLiveInvitations] = useState([])

  useEffect(() => {
    getAllInvitations(operation)
  }, [])

  useEffect(() => {
    if (response) {
      const invitations = response.data.data
      const filteredInvitations = invitations.filter(invitation => invitation.active);
      setLiveInvitations(filteredInvitations)

    }
  }, [response])



  return (
    <Routes>

      {
        liveInvitations &&
        liveInvitations.map((liveInvitation, index) => (
          <Route key={index} path={`${liveInvitation.label}/${liveInvitation.generals.eventName}`} element={<InvitationPage invitationID={liveInvitation._id} />} />
        ))

      }

      {
        liveInvitations &&
        liveInvitations.map((liveInvitation, index) => (
          <Route key={index} path={`share/${liveInvitation._id}`} element={<ShareGuests invitationID={liveInvitation._id} />} />
        ))

      }

      <Route path="/" element={<DiscoverPage />} />
      <Route path="/land-page" element={<LandPage />} />
      <Route path="/invitations" element={<InvitationsPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/contact-us" element={<HelpPage />} />
      <Route path="/contact-us" element={<HelpPage />} />
      <Route path="/discover" element={<DiscoverPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/*" element={<PageNotFound />} />

      <Route path="/admin"
        element={
          <AdminHOC>
            <AdminPage />
          </AdminHOC>
        } />




    </Routes>
  )
}
