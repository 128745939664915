import React from 'react'
import { FaCheck, decoration } from '../../helpers'
import { inv_planes } from '../../helpers/newInvitation'
import { all_features, app_features } from '../../helpers/app-features'
import { GoCheckCircle, FaCircleInfo } from '../../helpers'
import { Button, Popover, Row } from 'antd'

const list_items = [
    'Configuraciones generales',
    'Portada',
    'Mensajes',
    'Itinerario',
    'Dress code',
    'Mesa de regalos',
    'Galería',
    'Administración de invitados'
]

export const PricingContent = () => {


    return (
        <div className='pricing-main-container'>
            <div className='pricing-title-image-container'>
                <div className='pricing-title-subtext-container'>
                    <h2 className='pricing-title-page'>Conoce el plan perfecto para tu evento</h2>
                    <h4 className='pricing-sub-text'>Disfruta de todas las características sin importar el plan que elijas. Paga solo por el tiempo que mantengas tu invitación activa y personaliza cada detalle a tu gusto.</h4>
                </div>


            </div>
            <div className='pricing-cards-container'>
                {/* <img src={decoration.uno} className='pricing-decoration-image' /> */}
                {
                    inv_planes.map((item) => (
                        <div className='pricing-plan-card'>

                            <div className='pricing-card-title-icon-container'>
                                {item.icon}
                                <span>{item.name}</span>

                            </div>

                            <span className='pricing-card-description'>{item.description}</span>

                            <Row style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                flexDirection: 'row'
                            }}>
                                <span className='pricing-card-time'>{item.price}</span>
                                <span className='pricing-card-description'
                                    style={{
                                        width: 'auto', marginLeft: '5px',
                                        fontWeight: '600'
                                    }}>x {item.time}</span>
                            </Row>


                            <div className='pricing-card-price'>+ 100 características</div>
                        </div>
                    ))
                }
            </div>
            {/* <span className='pricing-features-title'>Explora todas las características</span> */}

            <div className='pricing-title-subtext-container'>
                <h2 className='pricing-title-page features-title'>Características</h2>
                {/* <h4 className='pricing-sub-text'>Ten acceso completo a todas las características y herramientas que ofrece nuestra plataforma para crear, personalizar y gestionar tus invitaciones digitales. <b> Paga solo por el tiempo que necesites mantener activas tus invitaciones</b>, elige el plan que mejor se adapte a tu evento, y disfruta de una experiencia sin límites.</h4> */}
            </div>


            <div className='pricing-features-conainer'>
                {
                    list_items.map((item) => (
                        <div style={{
                            marginBottom: '80px', width: '100%'
                        }}>
                            <div className='prcing-feature-row'>
                                <span className='pricing-feature-text' style={{
                                    fontWeight: 400
                                }}>{item}</span>
                            </div>


                            {
                                all_features.map((feature) => (
                                    feature.type === item &&
                                    <div className='prcing-feature-row'>

                                        <Row style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                                        }}>
                                            <span className='pricing-feature-text' style={{
                                                fontWeight: 600
                                            }}> {feature.bold}</span>
                                            <Popover content={feature.text} trigger="click" style={{ width: '100px' }} className='pricing-hidde-item'>
                                                <Button

                                                    icon={<FaCircleInfo style={{ color: '#00000050' }} />}
                                                    type='ghost'
                                                />
                                            </Popover>
                                        </Row>

                                        <FaCheck size={18} style={{ marginRight: '15px' }} className='pricing-hidde-item' />
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }

            </div>

        </div >
    )
}
