
import { Button, Layout, Popconfirm, Row, message } from "antd"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { appContext } from "../../context"
import { ManualLogo } from "../../components/Logo"
import {
    IoPricetagsOutline, IoArrowUndo, FaArrowUp, IoMdRefresh, BsClipboard, FaEnvelopeOpenText, FaRegEnvelope,
    RiSearchLine, HiOutlineEnvelope, BsClipboardFill, IoPricetags, HiEnvelopeOpen, IoIosHelpCircle, RiLoginCircleFill, PiLinkBold, FaWhatsapp, MdOutlineAdminPanelSettings, MdAdminPanelSettings
} from '../../helpers'

import { storage } from '../../firebase';
import { ref, deleteObject } from 'firebase/storage';
import { Footer } from "antd/es/layout/layout"

const { Header } = Layout
const baseProd = "https://www.iattend.mx"


export const HeaderBuild = ({ position, setOpenLogin, isVisible }) => {

    const { logged, logout, user, } = useContext(appContext)
    const [isScrollTop, setIsScrollTop] = useState(false);
    const [role, setRole] = useState('Owner')

    const getFirstLetterUpperCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase();
    }

    useEffect(() => {
        if (position === 'land-page') {
            const handleScroll = () => {
                if (window.scrollY >= 0 && window.scrollY <= 100) {
                    setIsScrollTop(false);
                } else {
                    setIsScrollTop(false);
                }
            };

            window.addEventListener('scroll', handleScroll);

            // Limpiar el event listener al desmontar el componente
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }

        if (user) {
            setRole(user.role)
        }

    }, []);


    const navItems = [
        // {
        //     name: 'Explora',
        //     icon: RiSearchLine,
        //     selected: RiSearchFill,
        //     path: "/land-page",
        //     position: "land-page"
        // },
        {
            name: 'Conoce',
            icon: HiOutlineEnvelope,
            selected: HiEnvelopeOpen,
            path: "/discover",
            position: "discover"
        },
        {
            name: 'Tablero',
            icon: BsClipboard,
            selected: BsClipboardFill,
            path: "/invitations",
            position: "invitations"
        },
        {
            name: 'Precios',
            icon: IoPricetagsOutline,
            selected: IoPricetags,
            path: "/pricing",
            position: "pricing"
        },
        {
            name: 'Admin',
            icon: MdOutlineAdminPanelSettings,
            selected: MdAdminPanelSettings,
            path: "/admin",
            position: "admin"
        },
        // {
        //     name: 'Ayuda',
        //     icon: IoIosHelpCircleOutline,
        //     selected: IoIosHelpCircle,
        //     path: "/contact-us",
        //     position: "help"
        // },

    ]


    return (
        <>
            <Header className="header-main-container web-opt"
            >

                <Row className="header-container" >



                    <ManualLogo isScrollTop={isScrollTop} position={position} date={new Date()} />


                    <Row style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        flexDirection: 'row',
                    }}>




                        <Row style={{
                            width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                            flexDirection: 'row'
                        }}>
                            {
                                navItems.map((item) => {
                                    // Condiciones para ocultar el elemento 'Admin'
                                    if (item.name === 'Admin' && (!logged || role !== 'Admin')) {
                                        return null;  // No renderiza nada
                                    }

                                    return (
                                        <Link to={item.path} key={item.name}>
                                            <div
                                                className={`nav-item-col ${item.position === position ? '--selected' : ''}`}>
                                                {
                                                    item.position === position
                                                        ? <item.selected className="nav-item-icon" />
                                                        : <item.icon className="nav-item-icon" />
                                                }
                                                <p className="nav-item-label">{item.name}</p>
                                            </div>
                                        </Link>
                                    );
                                })
                            }





                        </Row>


                        {
                            logged ?
                                <Button
                                    onClick={logout}
                                    id={role === 'Admin' ? 'on-logged-admin' : "on-logged-circle"} >
                                    {
                                        user ? getFirstLetterUpperCase(user.name)
                                            : 'A'

                                    }
                                </Button>

                                : <Link to="/login">
                                    <Button
                                        // onClick={() => setOpenLogin(true)} 
                                        id="iniciar-sesion-button">
                                        Iniciar sesión
                                    </Button>
                                </Link>
                        }




                    </Row>




                </Row>

            </Header >

            <Header className="header-main-container mobile-opt"
            // style={{
            //     opacity: isVisible ? 1 : 0,
            //     transition: 'all 0.5s ease'
            // }}
            >
                <Row
                    style={{
                        alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                        width: '100%', position: 'relative',
                    }}>
                    {/* <ManualLogo isScrollTop={isScrollTop} position={position} date={new Date()} /> */}
                    <span className='i-attend-text-logo'>I attend</span>

                    {
                        logged ?
                            <Button
                                onClick={logout}
                                id="on-logged-circle"
                            >
                                {
                                    user ? getFirstLetterUpperCase(user.name)
                                        : 'A'

                                }
                            </Button>

                            :
                            <Link to="/login">
                                <Button
                                    // onClick={() => setOpenLogin(true)} 
                                    id="iniciar-sesion-button">
                                    Iniciar sesión
                                </Button>
                            </Link>

                    }
                </Row>



            </Header >
        </>
    )
}

export const FooterMobile = ({ position, setOpenLogin, isVisible }) => {

    const { logged, logout, user, } = useContext(appContext)
    const [isScrollTop, setIsScrollTop] = useState(false);

    const getFirstLetterUpperCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase();
    }

    useEffect(() => {
        if (position === 'land-page') {
            const handleScroll = () => {
                if (window.scrollY >= 0 && window.scrollY <= 100) {
                    setIsScrollTop(false);
                } else {
                    setIsScrollTop(false);
                }
            };

            window.addEventListener('scroll', handleScroll);

            // Limpiar el event listener al desmontar el componente
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }

    }, []);


    const navItems = [
        // {
        //     name: 'Explora',
        //     icon: RiSearchLine,
        //     selected: RiSearchFill,
        //     path: "/land-page",
        //     position: "land-page"
        // },
        {
            name: 'Conoce',
            icon: HiOutlineEnvelope,
            selected: HiEnvelopeOpen,
            path: "/discover",
            position: "discover"
        },
        {
            name: 'Tablero',
            icon: BsClipboard,
            selected: BsClipboardFill,
            path: "/invitations",
            position: "invitations"
        },
        {
            name: 'Precios',
            icon: IoPricetagsOutline,
            selected: IoPricetags,
            path: "/pricing",
            position: "pricing"
        },
        // {
        //     name: 'Ayuda',
        //     icon: IoIosHelpCircleOutline,
        //     selected: IoIosHelpCircle,
        //     path: "/contact-us",
        //     position: "help"
        // },

    ]


    return (
        <Footer className="footer-main-container" style={{
            opacity: isVisible ? 1 : 0,
            transition: 'all 0.5s ease'
        }}>


            <Row style={{
                width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center',
                flexDirection: 'row', width: '100%',
            }}>
                {
                    navItems.map((item) => (
                        <Link to={item.path} >
                            <div
                                className={`nav-item-col ${item.position === position ? '--selected' : ''}`}>
                                {
                                    item.position === position ? <item.selected className="nav-item-icon" />
                                        : <item.icon className="nav-item-icon" />
                                }
                                <p className="nav-item-label">{item.name}</p>
                            </div>
                        </Link>

                    ))
                }
            </Row>

        </Footer >
    )
}

export const HeaderShared = ({ user, refreshPage }) => {


    return (
        <Header className={"header-main-container"}>

            <Row className="header-container" >

                {
                    user && (
                        <span className="header-shared-label"><b>Compartido con: </b> {user}</span>
                    )
                }


                <Button
                    onClick={refreshPage}
                    id='refresh-changes-button'
                    icon={<IoMdRefresh size={20} />}
                    style={{ position: 'relative', }}>

                    Actualizar datos

                </Button>

            </Row>

        </Header >
    )
}

export const HeaderDev = ({ invitation, setMode, mode, saved, onSaveChanges }) => {

    const { user, } = useContext(appContext)


    const moodeAndDelete = async (type) => {
        setMode(type)

        await deleteIfDifferent(invitation.cover?.featured_prod, invitation.cover?.featured_dev, deleteImageFB);

        if (invitation.gallery?.gallery_dev) {
            await Promise.all(invitation.gallery.gallery_dev.map(dev =>
                deleteIfDifferent(invitation.gallery.gallery_prod?.includes(dev) ? dev : null, dev, deleteImageFB)
            ));
        }

        if (invitation.dresscode?.images_dev) {
            await Promise.all(invitation.dresscode.images_dev.map(dev =>
                deleteIfDifferent(invitation.dresscode.images_prod?.includes(dev) ? dev : null, dev, deleteImageFB)
            ));
        }

    }

    const deleteIfDifferent = async (prod, dev, deleteFn) => {
        if (dev && dev !== prod) {
            await deleteFn(dev);
        }
    };

    const deleteImageFB = async (url) => {
        const filePath = extractFilePathFromURL(url)
        console.log(filePath)

        const imageRef = ref(storage, filePath);

        try {
            await deleteObject(imageRef);
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const extractFilePathFromURL = (url) => {
        const regex = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/([^/]+)\/o\/(.+)\?alt=media&token=.+/;
        const match = url.match(regex);
        if (!match) {
            throw new Error('URL does not match Firebase Storage base URL.');
        }
        const filePath = decodeURIComponent(match[2]);
        return filePath;
    };

    const copyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            message.success('Link copiado')
        } catch (err) {
            console.error('Error al copiar el texto: ', err);
        }
    };


    return (
        <Header className={"header-main-container"}>

            <Row className="header-container" >

                <Row style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                }}>


                    {
                        !saved
                            ?
                            <Popconfirm
                                title="Salir sin Guardar"
                                description="Tienes cambios sin guardar. Si sales ahora, todos los cambios se perderán. ¿Estás seguro de que deseas continuar?"
                                onConfirm={() => moodeAndDelete('my-invitations')}
                                placement="bottomLeft"
                                okText="Salir"
                                cancelText="Cancelar"
                                style={{ width: '400px' }}
                                id="popup-confirm"
                            >
                                <Button
                                    // onClick={() => setMode('my-invitations')}
                                    id='gc-cta-buttons'
                                    type='ghost'
                                    style={{ marginRight: '10px' }}
                                    icon={<IoArrowUndo size={25} />} />
                            </Popconfirm>
                            :
                            <Button
                                onClick={() => setMode('my-invitations')}
                                id='gc-cta-buttons'
                                type='ghost'
                                style={{ marginRight: '10px' }}
                                icon={<IoArrowUndo size={25} />} />
                    }



                    {
                        user.uid !== "66a57fd93d724e3f40549da0" && (
                            <div className="nav-items-container">

                                <p
                                    onClick={() => setMode('on-edit')}
                                    className={`header-item ${mode === 'on-edit' ? 'hi--selected' : ''}`}
                                    style={{
                                    }}>
                                    Diseño
                                </p>


                                {
                                    !saved
                                        ?
                                        <Popconfirm
                                            title="Salir sin Guardar"
                                            description="Tienes cambios sin guardar. Si sales ahora, todos los cambios se perderán. ¿Estás seguro de que deseas continuar?"
                                            onConfirm={() => moodeAndDelete('on-guests')}
                                            placement="bottomLeft"
                                            okText="Salir"
                                            cancelText="Cancelar"
                                            style={{ width: '400px' }}
                                            id="popup-confirm"
                                        >
                                            <p
                                                // onClick={() => setMode('on-guests')}
                                                className={`header-item ${mode === 'on-guests' ? 'hi--selected' : ''}`}
                                            >
                                                Invitados
                                            </p>
                                        </Popconfirm>
                                        :
                                        <p
                                            onClick={() => setMode('on-guests')}
                                            className={`header-item ${mode === 'on-guests' ? 'hi--selected' : ''}`}
                                        >
                                            Invitados
                                        </p>
                                }



                            </div>
                        )
                    }

                </Row>


                <div>
                    <Button
                        id='refresh-changes-button'
                        icon={<PiLinkBold size={18} />}
                        onClick={() => copyToClipboard(`${baseProd}/${invitation.label}/${invitation.generals.eventName}`)}>
                        Link de la invitación
                    </Button>


                    <Link to="https://wa.me/6145394836" target='_blank' style={{
                        margin: '0px 10px'
                    }}>
                        <Button
                            id='refresh-changes-button'
                            icon={<FaWhatsapp size={18} />}
                        >
                            ¿Necesitas ayuda?
                        </Button>
                    </Link>

                    <Button
                        onClick={onSaveChanges}
                        id='save-changes-button'
                        icon={<FaArrowUp size={15} />}
                        style={{ position: 'relative', }}>

                        Publicar cambios

                        {
                            !saved && (
                                <div style={{
                                    position: 'absolute', top: 0, right: -4,
                                    height: '15px', width: '15px', borderRadius: '50px',
                                    backgroundColor: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }} >
                                    <div style={{

                                        height: '10px', width: '10px', borderRadius: '50px',
                                        backgroundColor: '#FF0033'
                                    }}>

                                    </div>
                                </div>
                            )
                        }

                    </Button>


                </div>














            </Row>

        </Header >
    )
}

export const HeaderGuests = ({ setMode, refreshPage }) => {

    return (
        <Header className={"header-main-container"}>

            <Row className="header-container" >

                <Row style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                }}>


                    <Button
                        onClick={() => setMode('my-invitations')}
                        id='gc-cta-buttons'
                        type='ghost'
                        style={{ marginRight: '10px' }}
                        icon={<IoArrowUndo size={25} />} />





                    <div className="nav-items-container">

                        <p
                            onClick={() => setMode('on-edit')}
                            className={`header-item `}
                            style={{
                            }}>
                            Diseño
                        </p>


                        <p
                            onClick={() => setMode('on-guests')}
                            className={`header-item hi--selected`}
                        >
                            Invitados
                        </p>




                    </div>
                </Row>


                <div>

                    <Link to="https://wa.me/6145394836" target='_blank' style={{
                        margin: '0px 10px'
                    }}>
                        <Button
                            id='refresh-changes-button'
                            icon={<FaWhatsapp size={18} />}
                        >
                            ¿Necesitas ayuda?
                        </Button>
                    </Link>

                    <Button
                        onClick={refreshPage}
                        id='refresh-changes-button'
                        icon={<IoMdRefresh size={20} />}
                        style={{ position: 'relative', }}>

                        Actualizar datos

                    </Button>

                </div>















            </Row>

        </Header >
    )
}

