import { Button, Col, Input, Row, Switch } from 'antd'


import { BiSolidColorFill, LuSeparatorHorizontal, TbEyeClosed } from '../../helpers';
import { appContext } from '../../context';
import { useContext } from 'react';

export const BuildGreeting = ({ invitation, setInvitation, setSaved, onDesign }) => {

    // const { onDesign } = useContext(appContext)

    const onChangeTitle = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            greeting: {
                ...prevInvitation.greeting,
                title: e ? e.target.value : prevInvitation.greeting.title,
            },
        }));
        setSaved(false)
    }

    const onChangeDescription = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            greeting: {
                ...prevInvitation.greeting,
                description: e ? e.target.value : prevInvitation.greeting.description,
            },
        }));
        setSaved(false)
    }

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            greeting: {
                ...prevInvitation.greeting,
                active: e,
            },
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            greeting: {
                ...prevInvitation.greeting,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            greeting: {
                ...prevInvitation.greeting,
                separator: e,
            },
        }));
        setSaved(false)
    }

    return (
        <>
            {
                invitation ?
                    <div className='build-cover-main-container'>


                        <h2 className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            style={{
                                width: '100%',
                            }}
                        >Bienvenida</h2>

                        {
                            onDesign ?
                                <p className='module--description' >
                                    Aquí tienes la oportunidad de dar la bienvenida a tus invitados con un mensaje personalizado. Utiliza este espacio para expresar tu entusiasmo y gratitud por su futura asistencia, creando un ambiente cálido y acogedor desde el primer momento.</p>
                                : <div className='module--description' style={{ marginBottom: '30px' }} />
                        }


                        <Row className='build-componente-action-container'
                        >

                            <Col className='title-input-col-container'>

                                {
                                    invitation.greeting.active && (
                                        <>
                                            <p className='simple-content-label'>Título</p>

                                            <Input
                                                onChange={onChangeTitle}
                                                value={invitation.greeting.title}
                                                className='gc-input-text' />
                                        </>
                                    )
                                }


                            </Col>

                            <Row style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                            }}>

                                {
                                    invitation.greeting.active && (

                                        <>
                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeSeparator(!invitation.greeting.separator)}
                                                id={`gc-cta-buttons${invitation.greeting.separator ? '--selected' : ''}`}
                                                icon={<LuSeparatorHorizontal size={25} />} />

                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeBackground(!invitation.greeting.background)}
                                                id={`gc-cta-buttons${invitation.greeting.background ? '--selected' : ''}`}
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                                icon={<BiSolidColorFill size={25} />} />
                                        </>
                                    )
                                }



                                <Switch
                                    value={invitation.greeting.active}
                                    onChange={handleActive} />
                            </Row>





                        </Row>

                        {
                            invitation.greeting.active ?
                                <Col style={{
                                    width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column',
                                    marginTop: '20px'
                                }}>
                                    <p className='simple-content-label'>Descripción</p>

                                    {/* autoSize={{ minRows: 5, maxRows: 10 }} */}

                                    <Input.TextArea
                                        value={invitation.greeting.description}
                                        onChange={onChangeDescription}
                                        autoSize={{ minRows: 10, maxRows: 20 }}
                                        className='gc-input-text'
                                        style={{ borderRadius: '12px', minWidth: '290px' }} />

                                </Col>
                                : <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                        }



                    </div>
                    : <></>
            }
        </>
    )
}




