import { Button, Checkbox, Col, Dropdown, Input, InputNumber, Layout, Menu, Modal, Popconfirm, Row, message } from 'antd'
import React, { useEffect, useState } from 'react'
import {
    IoArrowUndo, FaUserPlus, MdEdit, HiLockClosed, LuCalendarCheck2, BsTicket, LuCalendarX2, MdAccessTime,
    IoMdAdd, FaLink, labelCorrection, IoMdArrowDropdown, formatDate, notificationsFormatDate,
    FiCopy, IoMdRefresh, LuMinus, IoMdSave, RiEyeCloseLine, RiEye2Line, FaArrowUp, LuExpand, LuShrink, FaSort,
    FiSearch, GoSortAsc, IoAddCircleOutline, HiMiniKey, RiShieldKeyholeFill, HiLockOpen, IoCloseSharp, BiHide, BiShow,
    FaRegTrashCan, IoNotifications, IoMdClose
} from '../helpers'
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { CustomButton } from '../components/CustomButton';
import { useInvitation } from '../hooks';
import { EditTickets, addShare, deleteGuestByID, deleteShare, getGuestsByInvitationID, getUpdatesByID, moveTickets, } from '../services/apiGuests';
import { NewGuestDrawer } from '../components/guests/NewGuestDrawer';
import { HeaderBuild, HeaderGuests, HeaderShared } from '../layouts/Build';

ChartJS.register(ArcElement, Tooltip, Legend);



export default function GuestsPage({ setMode, invitationID, mode, shared, saved, user }) {

    const { response, loading, error, operation } = useInvitation()

    const baseProd = "https://www.iattend.mx"
    const baseDev = "http://localhost:3000";

    const [available, setAvailable] = useState(null)
    const [confirmed, setConfirmed] = useState(null)
    const [waiting, setWaiting] = useState(null)
    const [canceled, setCanceled] = useState(null)
    const [tickets, setTickets] = useState(null)
    const [availableTickets, setAvailableTickets] = useState(null)

    const [guests, setGuests] = useState(null)
    const [guestsList, setGuestsList] = useState([])
    const [updatesList, setUpdatesList] = useState([])
    const [guestListCopy, setGuestListCopy] = useState([])

    const [onEditTickets, setOnEditTickets] = useState(false)
    const [onViewID, setOnViewID] = useState(false)
    const [drawerState, setDrawerState] = useState({
        currentGuest: null,
        onEditGuest: false,
        visible: false
    });
    const [onModal, setOnModal] = useState(false)
    const [onSorting, setOnSorting] = useState(false)
    const [onSearching, setOnSearching] = useState(false)
    const [guestsToAction, setGuestsToAction] = useState([])
    const [openCard, setOpenCard] = useState(null)
    const [onShare, setOnShare] = useState(false)
    const [sharedWith, setSharedWith] = useState([])
    const [onViewPasswords, setOnViewPasswords] = useState(false)
    const [newShared, setNewShared] = useState(null)
    const [onNews, setonNews] = useState(true)


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const onNewShare = () => {
        if (sharedWith.length < 3) {
            if (newShared) {
                if (validateEmail(newShared)) {
                    addShare(operation, invitationID, newShared);
                } else {
                    message.warning('El email debe tener un formato correcto');
                }
            }
        } else {
            message.warning('Solo puedes compartir con 3 personas');
        }

    };

    const onDeleteShare = (id) => {
        deleteShare(operation, invitationID, id);
    }

    const handleMoode = () => {
        setMode('my-invitations')
    }

    const refreshPage = () => {
        getGuestsByInvitationID(operation, invitationID)
        getUpdatesByID(operation, invitationID)
    }

    const onSaveNewTickets = (tickets_, newType) => {

        let guestsUpdated = []

        if (tickets_) {
            guestsUpdated = guests.guests.map(guest => {
                const availableCards = guest.companions.length === 0 ? 1 : guest.companions.length;
                return {
                    ...guest,
                    available_cards: availableCards
                };
            });
        } else {
            guestsUpdated = guests.guests
        }

        EditTickets(operation, guests, tickets_, guestsUpdated, newType);
        setOnEditTickets(false)
    };

    const handleTickets = () => {
        if (openCard) {
            message.warning('No puedes editar los pases de una invitación abierta')
        } else {
            setOnEditTickets(!onEditTickets)
            setAvailableTickets(tickets)
        }

    }

    const deleteGuest = (guestID) => {
        deleteGuestByID(operation, invitationID, guestID)
    }

    const moveToStorageTickets = (card) => {
        moveTickets(operation, invitationID, card)
    }

    const onOpenDrawer = (edit, guest) => {
        setDrawerState({
            currentGuest: guest,
            onEditGuest: edit,
            visible: true
        })

    }


    useEffect(() => {
        if (invitationID) {
            getGuestsByInvitationID(operation, invitationID)
            getUpdatesByID(operation, invitationID)
        }
    }, [])

    useEffect(() => {
        if (response) {

            if (response.data.ok) {
                switch (response.data.msg) {
                    case "Guests by ID":
                        console.log('my guests: ', response.data)
                        setTickets(response.data.guest.type === 'open' ? null : response.data.guest.tickets)
                        setAvailableTickets(response.data.guest.type === 'open' ? null : response.data.guest.tickets)
                        setGuestsList(response.data.guest.guests)
                        setOpenCard(response.data.guest.type === 'closed' ? false : true)
                        // setGuestListCopy(response.data.guest.guests)
                        setGuests(response.data.guest)
                        setSharedWith(response.data.guest.share)
                        // console.log('guest by id: ', response.data.guest)
                        const analytics = {
                            confirmado: 0,
                            rechazado: 0,
                            esperando: 0
                        };

                        response.data.guest.guests.forEach(guest => {
                            if (guest.state === 'confirmado') {
                                analytics.confirmado += guest.available_cards;
                            } else if (guest.state === 'rechazado') {
                                response.data.guest.tickets
                                    ? analytics.rechazado += guest.available_cards
                                    : analytics.rechazado += 1


                            } else if (guest.state === 'esperando') {
                                response.data.guest.tickets
                                    ? analytics.esperando += guest.available_cards
                                    : analytics.esperando += 1


                            }
                        });

                        setConfirmed(analytics.confirmado)
                        setCanceled(analytics.rechazado)
                        setWaiting(analytics.esperando)

                        response.data.guest.tickets && response.data.guest.type === 'closed'
                            ? setAvailable(response.data.guest.tickets - (analytics.confirmado + analytics.rechazado + analytics.esperando))
                            : setAvailable(0)


                        break;

                    case "Updates by ID":
                        console.log('in')
                        console.log('updates: ', response.data)
                        setUpdatesList(response.data.recentGuests)
                        break;

                    case "Guest updated successfully":
                        refreshPage()
                        setDrawerState({
                            currentGuest: null,
                            onEditGuest: null,
                            visible: false
                        })
                        break;

                    case "Share item added successfully":
                        setSharedWith(response.data.share)
                        setNewShared(null)
                        break;

                    case "Share item removed successfully":
                        setSharedWith(response.data.share)
                        break;

                    default:
                        break;
                }



            }
        }
    }, [response])


    const sortingBystate = () => {
        setOnSorting(true)
        const guestListSorted = [...guestsList].sort((a, b) => {
            if (a.state < b.state) {
                return -1;
            }
            if (a.state > b.state) {
                return 1;
            }
            return 0;
        });

        setGuestListCopy(guestListSorted);
    }

    const searchingByName = (e) => {
        setOnSearching(true)
        if (e.target.value) {
            const filteredGuestList = guestsList.filter(guest =>
                guest.name.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setGuestListCopy(filteredGuestList)
        }

    }

    const stopSorting = () => {
        setOnSorting(false)
        setOnSearching(false)
        setGuestListCopy(guestsList)
    }

    const copyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            message.success('Acceso copiado')
        } catch (err) {
            console.error('Error al copiar el texto: ', err);
        }
    };

    const guestsHeaderTable = [
        {
            label: 'Nombre',
            flex: 2,
            component:
                onSearching ?
                    <div style={{ position: 'relative' }}>
                        <Button onClick={stopSorting} type='ghost' id='gc-cta-buttons' icon={<FiSearch size={15} style={{ color: '#FCBA00' }} />} />
                        <Input
                            onChange={searchingByName}
                            className='gc-input-text'
                            style={{
                                position: 'absolute', bottom: '-30px', right: '-50px',
                                width: '200px', boxShadow: '0px 0px 8px rgba(0,0,0,0.10)'
                            }} />
                    </div>

                    : <Button onClick={searchingByName} type='ghost' id='gc-cta-buttons' icon={<FiSearch size={15} />} />
        },
        // FiSearch

        {
            label: 'Contacto',
            flex: 1
        },
        {
            label: 'ID de Acceso',
            flex: 1,
            component: <Button onClick={() => setOnViewID(!onViewID)} type='ghost' id='gc-cta-buttons'

                icon={!onViewID ? <RiEyeCloseLine size={15} /> : <RiEye2Line size={15} style={{ color: '#FCBA00' }} />} />
        },
        {
            label: tickets ? 'Pases' : 'Acompañantes',
            flex: 2
        },
        {
            label: 'Estado',
            flex: 1,
            component:
                onSorting ? <Button onClick={stopSorting} type='ghost' id='gc-cta-buttons' icon={<GoSortAsc size={20} style={{ color: '#FCBA00' }} />} />
                    : <Button onClick={sortingBystate} type='ghost' id='gc-cta-buttons' icon={<GoSortAsc size={20} />} />
        },
        {
            label: 'Acciones',
            flex: 1
        }
    ]

    const guestsHeaderTableOpen = [
        {
            label: 'Nombre',
            component:
                onSearching ?
                    <div style={{ position: 'relative' }}>
                        <Button onClick={stopSorting} type='ghost' id='gc-cta-buttons' icon={<FiSearch size={15} style={{ color: '#FCBA00' }} />} />
                        <Input
                            onChange={searchingByName}
                            className='gc-input-text'
                            style={{
                                position: 'absolute', bottom: '-30px', right: '-50px',
                                width: '200px', boxShadow: '0px 0px 8px rgba(0,0,0,0.10)'
                            }} />
                    </div>

                    : <Button onClick={searchingByName} type='ghost' id='gc-cta-buttons' icon={<FiSearch size={15} />} />
        },

        {
            label: tickets ? 'Pases' : 'Acompañantes'
        },
        {
            label: 'Estado',
            component:
                onSorting ? <Button onClick={stopSorting} type='ghost' id='gc-cta-buttons' icon={<GoSortAsc size={20} style={{ color: '#FCBA00' }} />} />
                    : <Button onClick={sortingBystate} type='ghost' id='gc-cta-buttons' icon={<GoSortAsc size={20} />} />
        },

    ]

    const analyticsCards = [
        {
            label: 'Disponibles',
            quantity: tickets ? available : null,
            icon: <BsTicket className='aly-card-icon' />
        },
        {
            label: 'Confirmados',
            quantity: confirmed,
            icon: <LuCalendarCheck2 className='aly-card-icon' />
        },
        {
            label: 'En espera',
            quantity: waiting,
            icon: <MdAccessTime className='aly-card-icon' />
        },
        {
            label: 'No asistirán',
            quantity: canceled,
            icon: <LuCalendarX2 className='aly-card-icon' />
        }
    ]

    const analyticsCardsgTicketsFree = [

        {
            label: 'Confirmados',
            quantity: confirmed,
            icon: <LuCalendarCheck2 className='aly-card-icon' />
        },
        {
            label: 'En espera',
            quantity: waiting,
            icon: <MdAccessTime className='aly-card-icon' />
        },

        {
            label: 'No asistirán',
            quantity: canceled,
            icon: <LuCalendarX2 className='aly-card-icon' />
        }
    ]

    const analyticsCardsOpen = [

        {
            label: 'Confirmados',
            quantity: confirmed,
            icon: <LuCalendarCheck2 className='aly-card-icon' />
        },

        {
            label: 'No asistirán',
            quantity: canceled,
            icon: <LuCalendarX2 className='aly-card-icon' />
        }
    ]

    const chartData = {
        labels: ['Disponibles', 'Confirmados', 'En espera', 'No asistiran'],
        datasets: [
            {
                // label: '# of Votes',
                data: [available, confirmed, waiting, canceled],
                backgroundColor: [
                    '#FFE48B',
                    '#FCBA00',
                    '#F3F3F6',
                    '#DCDCDC',
                ],
                borderColor: '#FFF',
                borderWidth: 2,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false, // Ocultar la leyenda
            },
        },
    };


    const hanldeUpdateCards = (card, menu, index) => {

        switch (card.last_action) {
            case 'accepted':
                return (
                    <div key={index} className='notifications-card'>
                        <span style={{ marginBottom: '5px' }} className='gsts-notification-label'>{card.name} Ha confirmado su asistencia.</span>
                        {
                            tickets ? <span className='gsts-notification-label'>Ha usado {card.companions.length}/{card.available_cards} pases disponibles</span>
                                : <span className='gsts-notification-label'>{card.companions.length} {`${card.companions.length > 1 ? 'personas' : 'persona'}`} {`${card.companions.length > 1 ? 'asistirán' : 'asistirá'}`}</span>
                        }

                        <Dropdown overlay={menu}>
                            <div className='gsts-companions-component'>
                                {
                                    tickets ? <span className='gsts-notification-label'>Lista de pases</span>
                                        : <span className='gsts-notification-label'>Acompañantes</span>
                                }

                                <IoMdArrowDropdown size={20} />
                            </div>
                        </Dropdown>

                        {
                            tickets && (
                                card.companions.length < card.available_cards
                                    ? <div className='save-tickets-container'>
                                        <span style={{ textAlign: 'center', marginBottom: '15px' }} className='gsts-notification-label'>¿Deseas mover pases sobrantes a disponibles?</span>
                                        <Row>
                                            {/* <Button id='save-tickets-button-secondary'>Mantener</Button> */}
                                            <Button onClick={() => moveToStorageTickets(card)} id='save-tickets-button'>Mover</Button>
                                        </Row>

                                    </div>
                                    : <></>
                            )

                        }
                        <span className='gsts-not-label-date'>{notificationsFormatDate(card.last_update_date)}</span>
                    </div>
                )


            case 'rejected':
                return (
                    <div key={index} className='notifications-card'>
                        <span style={{ marginBottom: '5px' }} className='gsts-notification-label'>{card.name} No podrá asistir</span>


                        {
                            tickets && (
                                card.companions.length < card.available_cards
                                    ? <div className='save-tickets-container'>
                                        <span style={{ textAlign: 'center', marginBottom: '15px', marginTop: '15px' }} className='gsts-notification-label'>¿Deseas mover pases sobrantes a disponibles?</span>
                                        <Row>
                                            {/* <Button id='save-tickets-button-secondary'>Mantener</Button> */}
                                            <Button onClick={() => moveToStorageTickets(card)} id='save-tickets-button'>Mover</Button>
                                        </Row>

                                    </div>
                                    : <></>
                            )
                        }
                        <span className='gsts-not-label-date'>{notificationsFormatDate(card.last_update_date)}</span>
                    </div>
                )

            case 'created':
                return (
                    <div key={index} className='notifications-card'>
                        <span style={{ marginBottom: '5px' }} className='gsts-notification-label'>Has añadido a {card.name} a tu lista de invitados</span>
                        <span style={{ marginBottom: '5px' }} className='gsts-notification-label'>Utilizaste {card.available_cards} pases</span>
                        <span className='gsts-not-label-date'>{notificationsFormatDate(card.last_update_date)}</span>
                    </div>
                )

            case 'edited':
                return (
                    <div key={index} className='notifications-card'>
                        <span style={{ marginBottom: '5px' }} className='gsts-notification-label'>Has editado los datos de {card.name}</span>
                        <span className='gsts-not-label-date'>{notificationsFormatDate(card.last_update_date)}</span>
                    </div>
                )

            default:
                break;
        }
    }

    const handleSelect = (e, guest) => {
        if (e.target.checked) {
            setGuestsToAction([...guestsToAction, guest]);
        } else {
            setGuestsToAction(guestsToAction.filter(g => g.id !== guest.id));
        }
    };

    useEffect(() => {
        setGuestListCopy(guestsList)
        console.log(guestsList)
    }, [guestsList])

    useEffect(() => {
        if (onEditTickets) {
            setOnShare(false)
        }
    }, [onEditTickets])

    useEffect(() => {
        if (onShare) {
            setOnEditTickets(false)
        }
    }, [onShare])

    const getFirstLetterUpperCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase();
    }




    return (
        <>

            <Layout
                className='responsive-web'
                style={{
                    position: 'relative',
                    alignItems: 'center', justifyContent: 'center',
                    backgroundColor: '#FFF',
                }}>
                {
                    shared ? <HeaderShared user={user} refreshPage={refreshPage} />
                        : <HeaderGuests position={'invitations'} setMode={setMode} refreshPage={refreshPage} />
                }

                <Layout className='scroll-invitation build-invitation-layout' style={{
                    marginTop: '80px'
                }} >

                    <div className='guests-general-container' style={{ position: 'relative' }}>

                        <div className='guests-info-container' >

                            <div className='title-buttons-container' style={{

                            }}>
                                <Row>

                                    <h2
                                        style={{
                                            // marginLeft: '25px'
                                        }}
                                        className='guests-title-page'>Mis Invitados</h2>
                                </Row>


                                <div className='gst-buttons-container'>

                                    <Popconfirm
                                        title={openCard ? 'Invitación Púbica' : 'Invitación Privada'}
                                        description={openCard ? "Al aceptar tu invitación será privada, por lo cual solo tus invitados podrán acceder." : "Al aceptar tu invitación será pública, por lo cualquier persona podrá acceder."}
                                        onConfirm={openCard ? () => onSaveNewTickets(100, 'closed') : () => onSaveNewTickets(null, 'open')}
                                        placement="bottomLeft"
                                        okText="Continuar"
                                        cancelText="Cancelar"
                                        style={{ width: '400px' }}
                                        id="popup-confirm"
                                    >
                                        {
                                            openCard ? <Button icon={<HiLockOpen size={15} />} id='gst-header-button'>Pública</Button>
                                                : <Button icon={<HiLockClosed size={15} />} id='gst-header-button'>Privada</Button>
                                        }
                                    </Popconfirm>


                                    {
                                        !shared && (
                                            <div className='on-share-guests-container' style={{
                                                marginLeft: '10px'
                                            }}>
                                                <Button onClick={() => setOnShare(!onShare)} icon={<FaUserPlus size={15} />} id={onShare ? 'gst-header-button-active' : 'gst-header-button'}>Compartir</Button>
                                                <div style={{
                                                    display: onShare ? 'flex' : 'none'
                                                }} className='on-share-container'>

                                                    <div className='on-share-title-container'>
                                                        <span className='on-share--title'>Compartir acceso</span>
                                                        <Button
                                                            onClick={() => setOnShare(false)}
                                                            id='gc-cta-buttons'
                                                            type='ghost'
                                                            icon={<IoCloseSharp size={15} />} />
                                                    </div>

                                                    <div className='add-new-shared-container'>
                                                        <Input placeholder={'Agrega correo'}
                                                            style={{ flex: 1 }}
                                                            value={newShared}
                                                            onChange={(e) => setNewShared(e.target.value)}
                                                            className='gc-input-text' />

                                                        <Button
                                                            onClick={onNewShare}
                                                            id='gc-cta-buttons'
                                                            type='ghost'
                                                            style={{
                                                                borderRadius: '10px', marginLeft: '5px',
                                                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                            }}
                                                            icon={<IoAddCircleOutline size={25} />} />
                                                    </div>

                                                    <div className='on-share-view-pass-container'>
                                                        <span className='on-share--label'>Personas que tienen acceso</span>
                                                        <Button
                                                            onClick={() => setOnViewPasswords(!onViewPasswords)}
                                                            id='gc-cta-buttons'
                                                            type='ghost'
                                                            icon={onViewPasswords ? <BiHide size={20} /> : <BiShow size={20} />} />
                                                    </div>

                                                    <div className='shared-people-container'>
                                                        <div className='shared-person-row-container'>
                                                            <div className='shared-person-row-container' style={{ margin: 0, padding: 0 }}>
                                                                <div className='shared-person-icon'>
                                                                    {
                                                                        localStorage.getItem("user-email") ? getFirstLetterUpperCase(localStorage.getItem("user-email"))
                                                                            : 'A'

                                                                    }
                                                                </div>
                                                                <Col style={{
                                                                    display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column'
                                                                }}>
                                                                    <span style={{
                                                                        marginLeft: '8px'
                                                                    }} className='on-share-single-label'>{localStorage.getItem("user-email")}</span>

                                                                </Col>

                                                            </div>

                                                            <span className='on-share-admin-label'>Administrador</span>
                                                        </div>

                                                        {
                                                            sharedWith.map((person, index) => (
                                                                <>
                                                                    <div key={index} className='shared-person-row-container'>
                                                                        <div className='shared-person-row-container' style={{ margin: 0, padding: 0 }}>
                                                                            <div className='shared-person-icon'>
                                                                                {
                                                                                    person ? getFirstLetterUpperCase(person.email)
                                                                                        : 'A'

                                                                                }
                                                                            </div>
                                                                            <Col style={{
                                                                                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column'
                                                                            }}>
                                                                                <span style={{
                                                                                    marginLeft: '8px'
                                                                                }} className='on-share-single-label'>{person.email}</span>
                                                                                <div style={{
                                                                                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                                                                                }}>
                                                                                    <Button
                                                                                        onClick={() => copyToClipboard(person.password)}
                                                                                        id='gc-cta-buttons'
                                                                                        type='ghost'
                                                                                        style={{ marginRight: '5px' }}
                                                                                        icon={<FiCopy size={15} />}
                                                                                    />
                                                                                    <span style={{ width: '100%' }} className='on-share-single-label'>{onViewPasswords ? person.password : '••••••••••'}</span>
                                                                                </div>

                                                                            </Col>

                                                                        </div>

                                                                        <Button
                                                                            onClick={() => onDeleteShare(person.id)}
                                                                            id='gc-cta-buttons'
                                                                            type='ghost'
                                                                            style={{ marginRight: '5px', zIndex: '10' }}
                                                                            icon={<FaRegTrashCan size={15} />} />
                                                                    </div>



                                                                </>


                                                            ))
                                                        }
                                                    </div>


                                                    <div className='share-copy-container'>
                                                        <Button
                                                            onClick={() => copyToClipboard(`${baseProd}/share/${invitationID}`)}
                                                            icon={<FaLink size={20} />}
                                                            id='share-copy-button'>Copiar link</Button>
                                                    </div>



                                                </div>


                                            </div>
                                        )
                                    }



                                    <div className='edit-tickets-container' style={{
                                        margin: '0px 10px'
                                    }}>
                                        <Button

                                            onClick={handleTickets}
                                            icon={<MdEdit size={15} />} id={onEditTickets ? 'gst-header-button-active' : 'gst-header-button'}>{availableTickets ? `${tickets} Pases` : 'Libre de pases'}</Button>
                                        <div style={{
                                            display: onEditTickets ? 'flex' : 'none'
                                        }} className='edit-tickets-buttons-container'>

                                            {
                                                tickets ?
                                                    <Popconfirm
                                                        title="Libre de pases"
                                                        description="Al aceptar tu evento será libre de pasaes, por lo cual no se podrá limitar el número de asistentes."
                                                        onConfirm={() => onSaveNewTickets(null, openCard ? 'open' : 'closed')}
                                                        placement="bottomLeft"
                                                        okText="Continuar"
                                                        cancelText="Cancelar"
                                                        style={{ width: '400px' }}
                                                        id="popup-confirm"
                                                    >
                                                        <Button icon={<HiMiniKey size={15} />} id="edit-tickets-button-minus" style={{
                                                            marginRight: '5px',
                                                            marginLeft: '0px'
                                                        }} />
                                                    </Popconfirm>
                                                    :
                                                    <Popconfirm
                                                        title="Evento con pases"
                                                        description="Al aceptar tu evento será limitado a un numero de pasaes, por lo cual tendrás el control de los asistentes."
                                                        onConfirm={() => onSaveNewTickets(100, openCard ? 'open' : 'closed')}
                                                        placement="bottomLeft"
                                                        okText="Continuar"
                                                        cancelText="Cancelar"
                                                        style={{ width: '400px' }}
                                                        id="popup-confirm"
                                                    >
                                                        <Button icon={<RiShieldKeyholeFill size={15} />} id="edit-tickets-button-minus" style={{
                                                            marginRight: '5px',
                                                            marginLeft: '0px'
                                                        }} />
                                                    </Popconfirm>
                                            }




                                            <Button onClick={() => onSaveNewTickets(availableTickets)} icon={<FaArrowUp size={15} />} id="edit-tickets-button-save" />
                                            <InputNumber
                                                disabled={availableTickets ? false : true}
                                                min={0}
                                                className='gc-input-text'
                                                style={{
                                                    border: '1px solid #737373', textAlign: 'center',
                                                    borderRadius: '30px', width: '100px', height: '30px', textAlign: 'center'
                                                }}
                                                value={availableTickets}
                                                onChange={(e) => setAvailableTickets(e)}
                                            />
                                            <Button
                                                disabled={availableTickets ? false : true}
                                                onClick={() => setAvailableTickets(availableTickets - 1)} icon={<LuMinus size={15} />} id="edit-tickets-button-minus" />

                                            <Button
                                                disabled={availableTickets ? false : true}
                                                onClick={() => setAvailableTickets(availableTickets + 1)} icon={<IoMdAdd size={15} />} id="edit-tickets-button-minus" />


                                        </div>
                                    </div>
                                    {/* <Button onClick={refreshPage} icon={<IoMdRefresh size={20} />} id='gc-cta-buttons'
                                        type='ghost' /> */}

                                    {
                                        !onNews && (
                                            <Button className='novedades-button' onClick={() => setonNews(true)} icon={<IoNotifications size={15} />} id='gst-header-button--news'>Novedades</Button>
                                        )
                                    }


                                </div>
                            </div>

                            <div className='gst-analytics-contaier' style={{

                            }}>
                                <h3 className='gsts-subtitle'>Informe de Asistencia</h3>
                                <div className='gsts-analytics'>
                                    <div className='guests-card-container'>
                                        {
                                            openCard ?
                                                analyticsCardsOpen.map((card) => (
                                                    <div className='analytic-card'>
                                                        {card.icon}
                                                        <span className='aly-card-quantity'>{card.quantity}</span>
                                                        <span className='aly-card-label'>{card.label}</span>
                                                    </div>
                                                )) :
                                                tickets ?
                                                    analyticsCards.map((card) => (
                                                        <div className='analytic-card'>
                                                            {card.icon}
                                                            <span className='aly-card-quantity'>{card.quantity}</span>
                                                            <span className='aly-card-label'>{card.label}</span>
                                                        </div>
                                                    ))
                                                    : analyticsCardsgTicketsFree.map((card) => (
                                                        <div className='analytic-card'>
                                                            {card.icon}
                                                            <span className='aly-card-quantity'>{card.quantity}</span>
                                                            <span className='aly-card-label'>{card.label}</span>
                                                        </div>
                                                    ))
                                        }
                                    </div>

                                    <div style={{ width: '160px', height: '160px' }}>
                                        <Pie data={chartData} options={options} />
                                    </div>
                                </div>
                            </div>

                            <div className='gst-analytics-contaier' style={{

                            }}>
                                <div className='gsts-title-cta-container'>
                                    <h3 className='gsts-subtitle'>Lista de Asistencia</h3>
                                    <Row>
                                        {/* <CustomButton
                                    secondary={true}
                                    styleText={{ fontWeight: 700 }}
                                    style={{
                                        marginRight: '30px'
                                    }}
                                    icon={<PiPaperPlaneTiltFill />} text={'Enviar Mensaje'} /> */}


                                        {
                                            !openCard && (
                                                <CustomButton
                                                    styleText={{ fontWeight: 700 }}
                                                    onClick={() => onOpenDrawer(false, null)}
                                                    icon={<IoMdAdd size={20} />} text={'Nuevo Invitado'} />
                                            )
                                        }



                                        <Button
                                            style={{
                                                marginLeft: '20px'
                                            }}
                                            onClick={() => setOnModal(true)} icon={<LuExpand size={20} />} id='gc-cta-buttons'
                                            type='ghost' />




                                    </Row>
                                </div>

                                <div className='gsts-th-container'>
                                    {
                                        openCard ?
                                            guestsHeaderTableOpen.map((header_item => (
                                                <dvi className="gsts-th-item">

                                                    {header_item.label}
                                                    {
                                                        header_item.component && (
                                                            <div className='gsts-th-component-cont'>
                                                                {header_item.component}
                                                            </div>
                                                        )
                                                    }

                                                </dvi>
                                            )))
                                            :
                                            guestsHeaderTable.map((header_item => (
                                                <dvi className="gsts-th-item" >
                                                    <div style={{
                                                        width: '50%',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center'
                                                    }}>
                                                        {header_item.label}
                                                    </div>

                                                    {
                                                        header_item.component && (
                                                            <div className='gsts-th-component-cont'>
                                                                {header_item.component}
                                                            </div>
                                                        )
                                                    }

                                                </dvi>
                                            )))
                                    }
                                </div>
                                <div className='gsts-table-content'>
                                    {

                                        guestListCopy.slice().reverse().map((guest, index) => {
                                            const menu = (
                                                <Menu>
                                                    {guest.companions.map((companion, i) => (
                                                        <Menu.Item key={i}>
                                                            {companion}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu>
                                            );

                                            return (
                                                <div key={index} className='gsts-row-container'>
                                                    <div className='gsts-row-item' style={{ position: 'relative', textAlign: 'center' }}>
                                                        {/* <Checkbox onChange={(e) => handleSelect(e, guest)} className='check-box-gsts' style={{ position: 'absolute', left: '15px' }} /> */}
                                                        {guest.name}
                                                    </div>
                                                    {
                                                        !openCard && (
                                                            <div className='gsts-row-item' >{guest.username}</div>
                                                        )
                                                    }

                                                    {
                                                        !openCard && (
                                                            <div className='gsts-row-item'>
                                                                <Button
                                                                    onClick={() => copyToClipboard(guest.id)}
                                                                    id='gc-cta-buttons'
                                                                    type='ghost'
                                                                    style={{ marginRight: '5px' }}
                                                                    icon={<FiCopy size={15} />}
                                                                />
                                                                <span className='gsts-notification-label' style={{ fontSize: onViewID ? '' : '24px' }}>{onViewID ? guest.id : '•••-•••'}</span>
                                                            </div>
                                                        )
                                                    }


                                                    <div className='gsts-row-item' >

                                                        <Dropdown overlay={menu}>
                                                            <div className='gsts-companions-component' style={{ width: '80%' }}>
                                                                <span className='gsts-notification-label'>
                                                                    {
                                                                        tickets ? <div>{guest.companions.length} / {guest.available_cards} Pases</div>
                                                                            : <div>{guest.companions.length}  {guest.companions.length === 1 ? "Invitado" : "Invitados"}</div>
                                                                    }
                                                                </span>
                                                                <IoMdArrowDropdown size={20} />
                                                            </div>
                                                        </Dropdown>

                                                    </div>
                                                    <div className="gsts-row-item">
                                                        <span className={`gsts-row-status ${guest.state}`}>{labelCorrection(guest.state)}</span>
                                                    </div>

                                                    {
                                                        !openCard && (
                                                            <div className='gsts-row-item'>
                                                                <Button onClick={() => onOpenDrawer(true, guest)} id='gsts-table-edit'>Editar</Button>
                                                            </div>
                                                        )
                                                    }




                                                </div>
                                            );
                                        })}
                                </div>


                            </div>

                        </div>

                        <div className={`guests-news-container ${onNews ? '' : 'guests-item-hidden'}`}>
                            <div style={{
                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                position: 'relative'
                            }}>
                                <h3 className='gsts-subtitle'>Novedades</h3>
                                <Button
                                    type='ghost'
                                    onClick={() => setonNews(false)}
                                    style={{ position: 'absolute', top: '-35px', right: '5px' }}
                                    id={`gc-cta-buttons`}
                                    icon={<IoMdClose size={20} />} />
                            </div>

                            <div className='gsts-notifications-container'>
                                {
                                    updatesList.map((card, index) => {
                                        const menu = (
                                            <Menu>
                                                {card.companions.map((companion, i) => (
                                                    <Menu.Item key={i}>
                                                        {companion}
                                                    </Menu.Item>
                                                ))}
                                            </Menu>
                                        );

                                        return (
                                            <>
                                                {
                                                    hanldeUpdateCards(card, menu, index)
                                                }
                                            </>
                                        );
                                    })
                                }
                            </div>
                        </div>

                        {/* <Button
                    onClick={() => setMode('my-invitations')}
                    id='gc-cta-buttons'

                    style={{ position: 'absolute', top: '20px', right: '20px', }}
                    type='ghost'>
                    <IoCloseSharp size={20} />
                </Button> */}

                    </div>
                </Layout>

            </Layout >


            <Modal
                open={onModal}
                closable={false}
                footer={null} // Opcional: Elimina el footer del modal si no es necesario
                style={{
                    top: 20,
                    width: '90%', // Ocupar todo el ancho de la pantalla
                    maxWidth: '1450px',
                    height: '80vh', // Ocupar toda la altura de la pantalla
                }}
                styles={{
                    height: '100%', // Asegurarse de que el contenido ocupe toda la altura
                    padding: '20px', // Añadir padding si es necesario
                    margin: 0,
                    padding: '50px'

                }}
                width="90%"
            >
                <div className='gst-analytics-contaier' style={{
                    padding: 0
                }}>
                    <div className='gsts-title-cta-container'>
                        <h3 className='gsts-subtitle'>Lista de Asistencia</h3>
                        <Row>
                            {/* <CustomButton
                                secondary={true}
                                styleText={{ fontWeight: 700 }}
                                style={{
                                    marginRight: '30px'
                                }}
                                icon={<PiPaperPlaneTiltFill />} text={'Enviar Mensaje'} /> */}

                            {
                                !openCard && (
                                    <CustomButton
                                        styleText={{ fontWeight: 700 }}
                                        onClick={() => onOpenDrawer(false, null)}
                                        icon={<IoMdAdd size={20} />} text={'Nuevo Invitado'} />
                                )
                            }


                            <Button
                                style={{
                                    marginLeft: '20px'
                                }}
                                onClick={() => setOnModal(false)} icon={<LuShrink size={20} />} id='gc-cta-buttons'
                                type='ghost' />



                        </Row>
                    </div>

                    <div className='gsts-th-container'>
                        {
                            openCard ?
                                guestsHeaderTableOpen.map((header_item => (
                                    <div className="gsts-th-item">

                                        {header_item.label}
                                        {
                                            header_item.component && (
                                                <div className='gsts-th-component-cont'>
                                                    {header_item.component}
                                                </div>
                                            )
                                        }

                                    </div>
                                )))
                                :
                                guestsHeaderTable.map((header_item => (
                                    <dvi className="gsts-th-item">

                                        <div style={{
                                            width: '50%',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            textAlign: 'center'
                                        }}>
                                            {header_item.label}
                                        </div>

                                        {/* {header_item.label} */}
                                        {
                                            header_item.component && (
                                                <div className='gsts-th-component-cont'>
                                                    {header_item.component}
                                                </div>
                                            )
                                        }

                                    </dvi>
                                )))
                        }
                    </div>
                    <div className='gsts-table-content' style={{ height: '720px' }}>
                        {

                            guestListCopy.slice().reverse().map((guest, index) => {
                                const menu = (
                                    <Menu>
                                        {guest.companions.map((companion, i) => (
                                            <Menu.Item key={i}>
                                                {companion}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                );

                                return (
                                    <div key={index} className='gsts-row-container'>
                                        <div className='gsts-row-item' style={{ position: 'relative' }}>
                                            {/* <Checkbox onChange={(e) => handleSelect(e, guest)} className='check-box-gsts' style={{ position: 'absolute', left: '15px' }} /> */}
                                            {guest.name}
                                        </div>
                                        {
                                            !openCard && (
                                                <div className='gsts-row-item'>{guest.username}</div>
                                            )
                                        }

                                        {
                                            !openCard && (
                                                <div className='gsts-row-item'>
                                                    <Button
                                                        onClick={() => copyToClipboard(guest.id)}
                                                        id='gc-cta-buttons'
                                                        type='ghost'
                                                        style={{ marginRight: '5px' }}
                                                        icon={<FiCopy size={15} />}
                                                    />
                                                    <span className='gsts-notification-label' style={{ fontSize: onViewID ? '' : '24px' }}>{onViewID ? guest.id : '••••••••••'}</span>
                                                </div>
                                            )
                                        }


                                        <div className='gsts-row-item'>

                                            <Dropdown overlay={menu}>
                                                <div className='gsts-companions-component' style={{ width: '80%' }}>
                                                    <span className='gsts-notification-label'>
                                                        {
                                                            tickets ? <div>{guest.companions.length} / {guest.available_cards} Pases</div>
                                                                : <div>{guest.companions.length}  {guest.companions.length === 1 ? "Invitado" : "Invitados"}</div>
                                                        }
                                                    </span>
                                                    <IoMdArrowDropdown size={20} />
                                                </div>
                                            </Dropdown>

                                        </div>
                                        <div className="gsts-row-item">
                                            <span className={`gsts-row-status ${guest.state}`}>{labelCorrection(guest.state)}</span>
                                        </div>

                                        {
                                            !openCard && (
                                                <div className='gsts-row-item'>
                                                    <Button onClick={() => onOpenDrawer(true, guest)} id='gsts-table-edit'>Editar</Button>
                                                </div>
                                            )
                                        }




                                    </div>
                                );
                            })}
                    </div>



                </div>

            </Modal>

            <NewGuestDrawer ticketsFree={tickets} visible={drawerState.visible} setDrawerState={setDrawerState} available={available} guests={guests} refreshPage={refreshPage} onEdit={drawerState.onEditGuest} currentGuest={drawerState.currentGuest} deleteGuest={deleteGuest} />
        </>
    )
}
