import React, { useContext, useEffect, useRef, useState } from 'react'
import Countdown from '../../components/invitation/CountDown'
import { darker, extratImageID, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { message } from 'antd'
import { storage } from '../../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import '../../styles/modules/cover.css'


export const CoverApp = ({ content, coverRef, inv, dev, height }) => {

    const { MainColor, theme, font, colorPalette } = useContext(appContext)

    const [timerColor, setTimerColor] = useState(null)

    useEffect(() => {
        if (content) {
            setTimerColor(content.timerColor)
        }
    }, [content])


    const types = [
        {
            name: 'Redondeado',
            content: {
                borderRadius: '12px',
                background: timerColor,
                width: '90%',
                padding: '10px 0'
            },
        },
        {
            name: 'Completo',
            content: {
                borderRadius: '0px',
                background: timerColor,
                width: '100%',
                padding: '10px 0'
            },
        },
        {
            name: 'Transparente',
            content: {
                borderRadius: '0px',
                background: 'transparent',
                width: '100%',
                padding: '10px 0'
            }
        }
    ]

    return (


        content && colorPalette ?
            <div className='module-cover-container'>
                <div
                    className={!dev ? 'cover-container' : 'cover-container-dev'}
                    style={{ height: height, padding: '0', minHeight: '630px' }}>


                    {
                        !content.background &&
                        <img src={dev ? content.featured_dev : content.featured_prod} />
                    }

                    <div className='background-cover'
                        style={{
                            background:
                                !content.background
                                    ? `linear-gradient(to top, ${theme ? darker(colorPalette.primary, 0.2) : darker(colorPalette.primary, 0.2)}, rgba(0,0,0,0))`
                                    : content.background,
                            flexDirection: content.flexDirection,

                        }}>



                        <div className='cover--title-container' style={{
                            alignItems: content.align, height: content.date ? '75%' : '100%',
                            padding: content.date ? 0 : '10px 0',

                        }}>
                            <h1 style={{
                                color: !content.color ? theme ? lighter(colorPalette.primary, 0.6) : lighter(colorPalette.accent, 0.6) : content.color, width: '100%',
                                textAlign: content.justify, fontSize: `${content.fontSize}em`, wordBreak: 'break-word',
                                opacity: content.opacity, fontFamily: content.image, fontWeight: content.fontWeight,
                                lineHeight: '1'
                            }}>{content.title}</h1>
                        </div>

                        {
                            content.date && (
                                <div style={{
                                    width: '100%',
                                    backgroundColor: `transparent`,

                                    height: '25%', marginTop: '10px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}>

                                    <Countdown color={content.color} colorPalette={colorPalette} dev={dev} targetDate={content.date} MainColor={MainColor} theme={theme} font={font} fontWeight={content.fontWeight} />

                                </div>
                            )
                        }



                    </div>

                </div>
            </div>
            : <></>

    )
}



