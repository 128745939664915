import React, { useContext, useEffect, useState } from 'react'
import { Button, Input, Layout, message } from 'antd';
import { CoverApp } from '../modules/invitation/Cover';
import { Greeting } from '../modules/invitation/Greeting';
import { Family } from '../modules/invitation/Family';
import { Quote } from '../modules/invitation/Quote';
import { Itinerary } from '../modules/invitation/Itinerary';
import { DressCode } from '../modules/invitation/DressCode';
import { TableGifts } from '../modules/invitation/TableGifts';
import { Notices } from '../modules/invitation/Notices';
import { Gallery } from '../modules/invitation/Gallery';
import { HeaderInvitation } from '../layouts/HeaderInvitation';
import { FooterInvitation } from '../layouts/FooterInvitation';
import { buttonsColorText, darker, lighter } from '../helpers/functions';
import { appContext } from '../context/AuthContext';
import { useInvitation } from '../hooks/customHook';
import { getInvitationbyID } from '../services/apiInvitation';
import { load, FaLock } from '../helpers';
import { getGuestsByInvitationID, guestLogin } from '../services/apiGuests';
import { ConfirmDrawer, ConfirmDrawerMobile, ConfirmDrawerWeb } from '../components/ConfirmDrawer';


const { Content } = Layout;

const colorApp = "#686033"

export const InvitationPage = ({ invitationID }) => {


    const [isVisible, setIsVisible] = useState(false);
    const { response, loading, error, operation } = useInvitation()
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const { setMainColor, MainColor, setTheme, theme, setFont, font, setColorPalette, colorPalette } = useContext(appContext)
    const [loader, setLoader] = useState(false)
    const [validated, setValidated] = useState(false)
    const [accessCode, setAccessCode] = useState(null)
    const [invitation, setInvitation] = useState(null)
    const [guestName, setGuestName] = useState("")
    const [visible, setVisible] = useState(false)
    const [visible2, setVisible2] = useState(false)
    const [currentGuest, setCurrentGuest] = useState(null)
    const [drawerReady, setdrawerReady] = useState(false)
    const [type, setType] = useState(null)
    const [guests, setGuests] = useState(null)


    const handleGuestLogin = () => {
        guestLogin(operation, invitationID, accessCode)
    }

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const margin = 680; // Ajusta este valor según tu preferencia


            if (currentScrollPos < margin) {
                setIsVisible(false);
            } else {
                setIsVisible(prevScrollPos > currentScrollPos);

                setPrevScrollPos(currentScrollPos);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const getData = () => {
        setLoader(true)
        getGuestsByInvitationID(operation, invitationID)
    }

    useEffect(() => {
        if (response) {
            if (response.data.ok) {
                switch (response.data.msg) {
                    case "Get invitation By Id":
                        const inv = response.data.data
                        setMainColor(inv.generals.color)
                        if (inv.generals.palette) {
                            setColorPalette({
                                primary: inv.generals.palette.primary,
                                secondary: inv.generals.palette.secondary,
                                accent: inv.generals.palette.accent,
                                buttons: inv.generals.palette.buttons,
                            })
                        } else {
                            setColorPalette({
                                primary: lighter('#000000', 0.9),
                                secondary: lighter('#000000', 0.8),
                                accent: darker('#000000', 0.4),
                                buttons: lighter('#000000', 0.5)
                            })
                        }
                        setTheme(inv.generals.theme)
                        setFont(inv.generals.font)
                        setInvitation(inv)
                        setValidated(true)
                        setLoader(false)
                        if (type === 'open') {
                            message.success(`¡Hola!`)
                        }
                        break;

                    //Primero hacemos un get guest by id, de ahi obtenemos el type
                    //Si el type es closed mostramos la seccion de login
                    //si el type es open hacemos un getInvitationbyID


                    case "Valid guest":
                        if (visible) {
                            setdrawerReady(true)
                        }
                        else {
                            setLoader(true)
                            getInvitationbyID(operation, invitationID)
                        }
                        // console.log(response.data.data)
                        setGuestName(response.data.data.username)
                        setCurrentGuest(response.data.data)
                        message.success(`¡Hola ${response.data.data.username}!`)
                        localStorage.setItem("guest-id", response.data.data.guestID)
                        localStorage.setItem("guest-token", response.data.data.token)



                        break;

                    case "Guest not found":
                        console.log('nou')
                        break

                    case "Guests by ID":
                        console.log('here: ', response.data.guest.type)
                        setLoader(false)
                        setType(response.data.guest.type)
                        setGuests(response.data.guest)
                    default:
                        break;
                }
            }

        }
    }, [response])


    const handlePosition = (id, index) => {
        switch (id) {
            case 1: return <Greeting key={index} id="greeting" content={invitation.greeting} dev={false} />
            case 2: return <Family key={index} id="family" content={invitation.family} dev={false} />
            case 3: return <Quote key={index} id="quote" content={invitation.quote} dev={false} />
            case 4: return <Itinerary key={index} id="itinerary" content={invitation.itinerary} dev={false} />
            case 5: return <DressCode key={index} id="dresscode" content={invitation.dresscode} dev={false} />
            case 6: return <TableGifts key={index} id="gifts" content={invitation.gifts} dev={false} />
            case 7: return <Notices key={index} id="notices" content={invitation.notices} dev={false} />
            case 8: return <Gallery key={index} id="gallery" content={invitation.gallery} dev={false} />

            default:
                break;
        }
    }

    useEffect(() => {
        if (visible) {
            if (type === 'closed') {
                guestLogin(operation, invitationID, localStorage.getItem("guest-id"))
            } else {
                setdrawerReady(true)
            }

        } else {
            setdrawerReady(false)
        }
    }, [visible])

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (type) {
            if (type === 'open') {
                setLoader(true)
                getInvitationbyID(operation, invitationID)
                setValidated(true)
            } else if (type === 'closed') {
                setValidated(false)
            }
        }
    }, [type])

    useEffect(() => {
        if (invitation) {
            document.title = invitation.cover.title;
        } else {
            document.title = 'I attend';
        }

    }, [invitation]);


    return (

        <>
            {

                !validated ?
                    <Layout style={{
                        width: '100%', height: '100vh'
                    }}>
                        <div className='locked-invitation-background'>
                            <div className='locked-invitation-container'>
                                <div className='locked-icon-container'>
                                    <FaLock />
                                </div>
                                <h1 className='locked-page-title'>Invitación Privada</h1>
                                <h2 className='locked-page-text'>Nos alegra mucho que seas parte de este evento tan especial. Esta invitación es <b>exclusiva para ti</b>. Ingresa tu código de invitado para continuar y disfrutar de esta experiencia única.</h2>
                                <Input
                                    value={accessCode}
                                    onChange={(e) => setAccessCode(e.target.value)}
                                    placeholder='Código de invitado' className='locked-input' />
                                <Button onClick={handleGuestLogin} id="locked-access-button" >Continuar</Button>
                            </div>


                        </div>
                    </Layout>
                    :
                    !loader && invitation ?
                        <Layout style={{
                            display: 'flex',
                            width1: '100%'
                        }}>
                            <HeaderInvitation visible={isVisible} content={invitation.cover} />
                            <Content
                                style={{
                                    marginTop: '0vh', // Ajusta este valor para que el contenido no quede detrás del header
                                    // marginBottom: '14vh', // Ajusta este valor para que el contenido no quede detrás del footer
                                    overflowY: 'scroll',
                                    height: 'auto', // Ajusta estos valores según el header y footer
                                    alignItems: 'center', justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                    backgroundColor: colorPalette.primary,
                                    position: 'relative', width: '100%'
                                }}
                            >

                                <Button
                                    className="action-button"
                                    onClick={() => setVisible(true)}
                                    style={{
                                        color: buttonsColorText(darker(colorPalette.buttons, 0.8)),
                                        fontFamily: font,
                                        background: `linear-gradient(to right, ${lighter(colorPalette.buttons, 0.1)} 0%, ${darker(colorPalette.buttons, 0.8)} 51%, ${darker(colorPalette.buttons, 0.9)} 100%)`
                                    }}
                                >
                                    CONFIRMAR
                                </Button>

                                <CoverApp content={invitation.cover} dev={false} height={'100vh'} />

                                {
                                    invitation.generals.positions.map((position, index) => (
                                        handlePosition(position, index)
                                    ))
                                }

                            </Content>
                            <FooterInvitation />
                        </Layout>

                        : <div style={{
                            height: '100vh', display: 'flex', alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: lighter(MainColor, 0.9)
                        }}>
                            <img src={load} style={{
                                width: '10%'
                            }} />
                        </div>
            }

            <ConfirmDrawerMobile guests={guests} type={type} visible={drawerReady} setVisible={setVisible} MainColor={MainColor} theme={theme} currentGuest={currentGuest} invitation={invitation} />
            {/* <ConfirmDrawerWeb visible={visible2} setVisible={setVisible2} MainColor={MainColor} theme={theme} /> */}

        </>

    )
}
